import { graphql } from 'gatsby';

import React, { Component } from 'react';
import get from 'lodash/get';

import Layout from '../layout/Layout';
import { PageHero, PageIntroduction } from '../common/components';
import CareersJobOpenings from './CareersJobOpenings';

interface Props {
  data: any;
  location:any;
}

class Careers extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const body = get(data, 'page.data.body');

    return (
      <Layout data={data} location={this.props.location}>
        {Array.isArray(body) &&
          body.map(slice => {
            switch (slice.__typename) {
              case 'PrismicCareersBodyHero':
                return (
                  <PageHero key={slice.id + slice.__typename} data={slice} />
                );
              case 'PrismicCareersBodyIntroduction':
                return (
                  <PageIntroduction
                    key={slice.id + slice.__typename}
                    data={slice}
                  />
                );
              case 'PrismicCareersBodyJobOpenings':
                return (
                  <CareersJobOpenings
                    key={slice.id + slice.__typename}
                    data={slice}
                  />
                );
              default:
                return null;
            }
          })}
      </Layout>
    );
  }
}

export default Careers;

export const query = graphql`
  query CareersQuery($slug: String!) {
    page: prismicCareers(id: { eq: $slug }) {
      ...Careers
    }
  }
`;
