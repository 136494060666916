import { Button } from 'antd';
import styled from 'styled-components';

const ButtonWithStyled = styled(Button)`
  /* padding: ${props => (props.isMobile ? '5px 10px' : '10px 20px')}; */
  background-color: ${props => props.theme.color.primary.main};
  border:none;
  border-radius: 0;
  color: black;
  ${props => props.isMobile && 'border-radius: 0;'}
  min-height: 52px;
  font-size:16px;
`;

export default ButtonWithStyled;
