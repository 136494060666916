import React, { Component } from 'react';
import styled, { withTheme, css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Row, Col } from 'antd';
import { Title, Spacer } from '.';
// import * as flowerGold from '../../assets/flower-1-gold.svg';
interface Props {
  title?: string;
  theme: any;
  backgrounds?: any;
  className?: any;
  height?: any;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;
`;
const InnerContainer = styled(Row)`
  position: relative;
  min-height: ${props => (props.height ? props.height : 250)}px;
  ${props =>
    props.height &&
    css`
      height: ${props => props.height}px;
    `}
  width: 100%;
  /* margin: 20px 40px; */

  max-width: 900px;
  ${breakpoint('md')`
    max-width: 1000px;
  `}
  ${breakpoint('lg')`
    max-width: 1200px;
  `}
`;

const DescriptionCol = styled(Col)`
  text-align: center;
  // can't use stack if display is flex
  // display: flex !important;
  // justify-content: center;
`;
const DescriptionColSpacer = styled(DescriptionCol)`
  margin-bottom: 1em;
`;

const TitleText = styled(Title)`
  color: ${props => props.theme.color.primary.main} !important;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
`;

class SectionContainer extends Component<Props, any> {
  render() {
    const {
      title,
      children,
      theme,
      backgrounds,
      className,
      height,
    } = this.props;
    return (
      <Container className={className}>
        <InnerContainer height={height}>
          {title && (
            <DescriptionCol xs={24}>
              <TitleText>{title}</TitleText>
            </DescriptionCol>
          )}
          {title && (
            <DescriptionColSpacer xs={24}>
              <Spacer color={theme.color.secondary.main} />
            </DescriptionColSpacer>
          )}
          <DescriptionCol xs={24}>{children}</DescriptionCol>
        </InnerContainer>
        {backgrounds && backgrounds.map(bg => bg)}
      </Container>
    );
  }
}

export default withTheme(SectionContainer);
