import styled, { css, withTheme } from 'styled-components';
import { Checkbox } from 'antd';

const CheckboxWithStyled = styled(Checkbox)`
color: ${props => props.theme.color.text.light} !important;

.ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 22px;
    height: 22px;
    background-color: ${props => props.theme.color.background.main};
    border-collapse: separate;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: 0px; 
    border-radius: 0px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.color.primary.main} !important;
    border-color: ${props => props.theme.color.primary.main} !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
    left: 7px;
}

`;

export default withTheme(CheckboxWithStyled);