import React, { useCallback, useEffect, useState } from 'react';
import { exists, window } from 'browser-monads';
import styled, { css, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Media from 'react-media';
import TweenOne from 'rc-tween-one';

import { BANNER_CHANGE_SECOND } from '../../constants';
import withPrismicHeader from './withPrismicHeader';

const Container = styled.div`
  z-index: 11;
`;

const BannerText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #b59660;
  color: #fff;
  padding: 6px;
  font-weight: 400;
  font-size: 12px;
  min-height: 60px;
  z-index: 4;
  width: 100%;
  position: fixed;
  top: 60px;
  border-top: 5px solid #000000;

  ${breakpoint('lg')`
   ${css`
     font-size: 12px;
     min-height: 0px;
     top: 0;
     border-top: none;
   `}
  `}
`;

const DesktopBannerPadding = styled.div`
  padding-bottom: 0px;

  ${breakpoint('lg')`
   ${css`
     padding-bottom: 30px;
   `}
  `}
`;

interface BannerProps {
  banners: string[];
}

const Banner = ({ banners, theme }: BannerProps) => {
  const breakpointSize = theme.breakpoints.lg;

  const [currentBannerIndex, setCurrentBannerIndex] = useState<number>(0);
  const [isPauseAnim, setIsPauseAnim] = useState<boolean>(false);
  const [animMoment, setAnimMoment] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (isPauseAnim) {
      let newIndex = currentBannerIndex + 1;
      if (newIndex >= banners.length) {
        newIndex = 0;
      }

      setAnimMoment(0);
      setIsPauseAnim(false);
      setCurrentBannerIndex(newIndex);
    }
  }, [isPauseAnim, banners, currentBannerIndex]);

  const onChangeText = (bannerIndex) => {
    setAnimMoment(undefined);
    setIsPauseAnim(true);
  };

  if (banners.length > 0) {
    return (
      <Container id="Banner">
        <DesktopBannerPadding />
        <BannerText>
          <TweenOne
            style={{ opacity: 0 }}
            animation={[
              { opacity: 1, duration: 500 },
              { opacity: 1, duration: BANNER_CHANGE_SECOND * 1000 },
              banners.length > 1 ? {
                opacity: 0,
                duration: 500,
                onComplete: onChangeText,
              } : {},
            ]}
            moment={animMoment}
          >
            {banners[currentBannerIndex]}
          </TweenOne>
        </BannerText>
      </Container>
    );
  }

  return null;
};

export default withPrismicHeader(withTheme(Banner));
