import styled, { css, withTheme } from 'styled-components';
import { DatePicker } from 'antd';

const DatePickerWithStyled = styled(DatePicker)`
    width: 100% !important;

    &:hover {
        border-style: none;
        border-radius: 0px !important;
    }

    &:focus {
        border-style: none;
        border-radius: 0px !important;
    }

    * {
        border-color: ${props => props.theme.color.background.main} !important;
        box-shadow: none !important;
        border-radius: 0px !important;
    }

    .ant-calendar-picker-icon {
        color: ${props => props.theme.color.text.main};
    }

    .ant-input[disabled] {
        color: ${props => props.theme.color.text.gray};
        border-color: ${props => props.theme.color.background.black} !important;
        background-color: transparent;
        cursor: not-allowed;
        opacity: 1; 
    }

    .ant-input[disabled]:hover {
        border-color: ${props => props.theme.color.background.black} !important;
    }

`;

export default withTheme(DatePickerWithStyled);