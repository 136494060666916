import React, { Component } from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { window, exists } from 'browser-monads';
import { Title, Paragraph, SectionContainer, Image, Link } from '.';
import { theme } from '../theme/';
import Media from 'react-media';

const Container = styled(SectionContainer)`
  position: relative;
  min-height: 250px;
  margin: 20px 11% !important;
  padding-bottom: 0;

  ${breakpoint('md')`
    padding-bottom: 30px;
  `}
`;

const TitleText = styled(Title)`
  text-align: left;
  text-transform: uppercase;
`;

const ReadMoreLinkText = styled(Paragraph)`
  text-align: left;
  font-weight: bold !important;
`;

const ImageCol = styled(Col)`
  overflow: hidden;
`;

const Img = styled(Image)`
  /* transition: transform 0.5s ease;
  :hover {
    transform: scale(1.3);
  } */
`;

const ParagraphContext = styled(Paragraph)`
  text-align: left;
`;

const DescriptionCol = styled(Col)`
  padding: 20px 0 !important;

  ${breakpoint('md')`
    padding-${props =>
      props.display ? props.display : 'left'}: 20px !important;
  `}

  ${breakpoint('lg')`
      padding: 0 20px !important;
      padding-${props =>
        props.display ? props.display : 'left'}: 100px !important;
  `}
`;

interface Props {
  title: any;
  titleLevel?: number;
  description: any;
  image: any;
  readMoreUrl?: string;
  readMoreText?: string;
  readMoreLinkTarget?: string;
  displayImagePosition: 'right' | 'left';
  goldenLink?: boolean;
}

class BodyImageSection extends Component<Props, any> {
  renderDesktop = () => {
    const {
      title,
      titleLevel,
      description,
      image,
      readMoreUrl,
      readMoreText,
      readMoreLinkTarget = '',
      displayImagePosition,
      goldenLink,
    } = this.props;

    return (
      <Row type="flex" justify="center" align="middle">
        {/* Name Architecture */}
        <Col xs={24} md={0}>
          {title && (
            <TitleText level={titleLevel || 1} size={1}>
              {title}
            </TitleText>
          )}
        </Col>
        {/* Golf Image Left */}
        {displayImagePosition === 'left' ? (
          <ImageCol xs={24} md={12}>
            <Img data={image} width="100%" />
          </ImageCol>
        ) : null}

        {/* Context */}
        <DescriptionCol xs={24} md={12} display={displayImagePosition}>
          <Row type="flex" justify="start">
            <Col xs={0} md={24}>
              {title && (
                <TitleText level={titleLevel || 1} size={1}>
                  {title}
                </TitleText>
              )}
            </Col>
            <Col>
              {description && (
                <ParagraphContext small>{description}</ParagraphContext>
              )}
              {readMoreText && (
                <Link to={readMoreUrl} target={readMoreLinkTarget}>
                  <ReadMoreLinkText
                    small
                    color={
                      goldenLink
                        ? theme.color.primary.main
                        : theme.color.text.main
                    }
                  >
                    {readMoreText}
                  </ReadMoreLinkText>
                </Link>
              )}
            </Col>
          </Row>
        </DescriptionCol>
        {/* Golf Image Right */}
        {displayImagePosition === 'right' ? (
          <ImageCol xs={24} md={12}>
            <Img data={image} width="100%" />
          </ImageCol>
        ) : null}
      </Row>
    );
  };

  renderMobile = () => {
    const {
      title,
      titleLevel,
      description,
      image,
      readMoreUrl,
      readMoreText,
      readMoreLinkTarget = '',
      displayImagePosition,
      goldenLink,
    } = this.props;

    return (
      <Row type="flex" justify="center" align="middle">
        {/* Name Architecture */}
        <Col xs={24} md={0}>
          {title && (
            <TitleText level={titleLevel || 1} size={1}>
              {title}
            </TitleText>
          )}
        </Col>
        {/* Golf Image Left */}
        <ImageCol xs={24} md={12}>
          <Img data={image} width="100%" />
        </ImageCol>
        {/* Context */}
        <DescriptionCol xs={24} md={12} display={displayImagePosition}>
          <Row type="flex" justify="start">
            <Col xs={0} md={24}>
              {title && (
                <TitleText level={titleLevel || 1} size={1}>
                  {title}
                </TitleText>
              )}
            </Col>
            <Col>
              {description && (
                <ParagraphContext small>{description}</ParagraphContext>
              )}
              {readMoreText && (
                <Link to={readMoreUrl} target={readMoreLinkTarget}>
                  <ReadMoreLinkText
                    small
                    color={
                      goldenLink
                        ? theme.color.primary.main
                        : theme.color.text.main
                    }
                  >
                    {readMoreText}
                  </ReadMoreLinkText>
                </Link>
              )}
            </Col>
          </Row>
        </DescriptionCol>
      </Row>
    );
  };

  render() {
    return (
      <Container id={window.innerWidth}>
        <Media
          query={`(min-width: ${theme.breakpoints.sm}px)`}
          defaultMatches={true}
          render={() => this.renderDesktop()}
        />
        <Media
          query={`(max-width: ${theme.breakpoints.sm - 1}px)`}
          defaultMatches={true}
          render={() => this.renderMobile()}
        />
        {/* {exists(window) && window.innerWidth > theme.breakpoints.sm
          ? this.renderDesktop()
          : this.renderMobile()} */}
      </Container>
    );
  }
}

export default BodyImageSection;
