import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import get from 'lodash/get';
import compact from 'lodash/compact';

const withPrismicHeader = (WrappedComponent) => {
  const withPrismicHeaderComponent = ({ ...props }) => {
    return (
      <StaticQuery
        query={queryPrismicHeader}
        render={({ allPrismicHeaderMenu }) => {
          const prismicData =
            allPrismicHeaderMenu.edges.find(
              (o) => o.node.lang === props.lang
            ) || null;

          let banners = ['banner_text_1', 'banner_text_2', 'banner_text_3'].map(
            (item) => {
              return get(prismicData, 'node.data.' + item);
            }
          );
          banners = compact(banners);

          return (
            <WrappedComponent
              prismicData={prismicData}
              hasBanner={banners.length > 0}
              banners={banners}
              {...props}
            />
          );
        }}
      />
    );
  };

  return withPrismicHeaderComponent;
};

export default withPrismicHeader;
const queryPrismicHeader = graphql`
  query PrismicHeaderQuery {
    allPrismicHeaderMenu {
      ...Header
    }
  }
`;
