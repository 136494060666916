import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Icon } from 'antd';
import { Slider } from '.';

import { theme } from '../theme';

const SliderContainer = styled.div`
  .slick-slider {
    margin-top: 25px;
    margin-bottom: 50px;
    margin-left: 15%;
    margin-right: 15%;

    ${breakpoint('md')`
      margin-left: 10%;
      margin-right: 10%;
    `}
  }
`;

const CarouselArrowIcon = styled(Icon)`
  width: 40px !important;
  height: 40px !important;
  font-size: 40px !important;
  color: ${props => props.theme.color.primary.main}!important;

  ${breakpoint('lg')`
    &:hover {
      color: ${props => props.theme.color.primary.dark}!important;
    }
  `}

  ${props =>
    props.type &&
    (props.type === 'right' || props.type === 'left') &&
    css`
      ${props.type}: -50px !important;
    `}
`;

const CarouselNextArrow = props => (
  <CarouselArrowIcon
    type="right"
    onClick={props.onClick}
    className={props.className}
  />
);

const CarouselPrevArrow = props => (
  <CarouselArrowIcon
    type="left"
    onClick={props.onClick}
    className={props.className}
  />
);

class ItemsSlider extends React.Component<any, any> {
  slider: any;
  componentDidUpdate(prevProps) {
    if (this.props.settings) {
      if (
        prevProps.settings.autoplay !== this.props.settings.autoplay &&
        this.slider
      ) {
        if (this.props.settings.autoplay) {
          this.slider.innerSlider.play();
          this.slider.slickPlay();
        } else {
          this.slider.slickPause();
        }
      }
    }
  }
  render() {
    const { sliderItems, settings = {} } = this.props;
    const slidersSettings = {
      ...{
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        // initialSlide: 0,
        autoplay: false,
        rtl: false,
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        responsive: [
          {
            // Desktop breakpoint
            breakpoint: theme.breakpoints.xl,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            // Tablet breakpoint
            breakpoint: theme.breakpoints.lg,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      ...settings,
    };
    return (
      <SliderContainer>
        <Slider
          settings={slidersSettings}
          ref={slider => (this.slider = slider)}
        >
          {sliderItems}
        </Slider>
      </SliderContainer>
    );
  }
}

export default ItemsSlider;
