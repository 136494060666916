import styled, { css, withTheme } from 'styled-components';
import { InputNumber } from 'antd';

const InputNumberWithStyled = styled(InputNumber)`
    color: ${props => props.theme.color.text.light} !important;

    height: 50px !important;
    width: 100% !important;
    padding: 10px 0px !important;
    border: 0px !important;
    border-radius: 0px !important;

    .ant-input-number-handler-wrap {
        border-left: 0px;
        border-radius: 0px;
    }

    .ant-input-number-input {
        cursor: pointer !important;
        caret-color: transparent;
    }
`;

export default withTheme(InputNumberWithStyled);