import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

// Note: use it with a parent component with a "position:relative"
// SVG images available are in /assets/
const FlowerPattern = styled.img`
  position: absolute;
  display: ${props => (props.forceDisplay ? 'block' : 'none')};
  ${breakpoint('lg')`
    display: block;
  `}
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : '10%')};
  right: ${props => (props.right ? props.right : '10%')};
  bottom: ${props => (props.bottom ? props.bottom : '10%')};
  opacity: ${props => (props.opacity ? props.opacity : 1)};
  z-index: 0;
`;

export default FlowerPattern;
