import { USER_ROLES } from './userRoles';

const LIMITATION = {
  [USER_ROLES.AGENT]: {
    min: 2,
    max: 50,
  },
  [USER_ROLES.INDIVIDUAL]: {
    min: 2,
    max: 8,
  },
  [USER_ROLES.ADMIN]: {
    min: 1,
    max: 100,
  },
  [USER_ROLES.STAFF]: {
    min: 1,
    max: 100,
  },
  [USER_ROLES.GLOBAL_ADMIN]: {
    min: 0,
    max: 0,
  },
};
export const getLimitation = (roles = []) => {
  let { min, max } = LIMITATION[USER_ROLES.INDIVIDUAL]; // default is INDIVIDUAL
  if (roles) {
    roles.map((role) => {
      const limit = LIMITATION[role];
      if (limit.min > min) min = limit.min;
      if (limit.max > max) max = limit.max;
    });
  }
  return { min, max };
};
