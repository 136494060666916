import React, { useState } from "react";
import { StaticQuery, graphql } from 'gatsby';
import { Row, Col, Button, Icon, Form } from 'antd';
import styled, { css, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { get, find } from 'lodash';
import { FormProps } from 'antd/lib/form';
import { Input, Text, Link, Image, Title } from '../common/components';
import { getInternalUrl } from '../utils';
import { EMAIL_REGEX } from '../constants/pattern';
import { sendMail } from '../utils/sendMail';

const localStorage = require('localStorage');

const marginContainerResponsive = css`
  margin-top: 10px;
  ${breakpoint('lg')`
    margin-top:0;
  `}
`;

const Container = styled.div`
  padding: 10px 20px 10px 20px;
  background-color: ${props => props.theme.color.background.black};

  ${breakpoint('lg')`
    width:100%;
    padding: 20px 30px 20px 30px;
  `}
`;

const CopyrightText = styled(Text)`
  ${marginContainerResponsive}
`;

const MenuContainer = styled.div`
  overflow: hidden;
  display: flex;
  ${breakpoint('xs')`
    flex-direction:column;
  `}
  ${breakpoint('lg')`
    flex-direction:row;
  `}
  ${marginContainerResponsive}
`;

const LinkMenu = styled(Link)`
  ${breakpoint('lg')`
    text-align: center;
  `}
  color: ${props =>
    props.isCurrentPage
      ? props.theme.color.text.dark
      : props.theme.color.primary.light} !important;
  font-family: ${props => props.theme.fontFamily};
  font-size: 16px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  ${breakpoint('lg')`
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  `}
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${props => props.theme.color.text.dark};
  }
`;

const SocialMediaContainer = styled.div`
  ${marginContainerResponsive}
`;

const SocialMediaTitle = styled(Title)``;

const SocialMediaLinkContainer = styled.div`
  margin-top: 15px;
  ${breakpoint('md')`
    margin-top: 25px;
  `}
`;

const SocialMediaLink = styled(Link)`
  margin: 15px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const SocialMediaIcon = styled(Image)`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const NewsletterContainer = styled.div`
  ${marginContainerResponsive}
`;

const NewsletterTitle = styled(Title)`
  text-transform: uppercase;
`;

const NewsletterTextBoxContainer = styled(Input.Container)`
  height: 44px;
  ${breakpoint('md')`
    width: 95%;
  `}
  max-width: 400px;
  padding: 15px;
  margin-top: 11px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 10px !important;
`;

const ArrowButton = styled(Button)`
  background: ${props => props.theme.color.primary.main};
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: black;

  &:hover,
  &:focus {
    background: ${props => props.theme.color.primary.main};
    color: black;
  }

  &[disabled],
  &[disabled]:hover {
    background: ${props => props.theme.color.primary.dark};
    color: black;
  }
`;

const FooterBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f0f0f0;
  color: black;
  padding: 12px;
  z-index: 55;
  display: flex;
  align-items: center;
  justify-content: center;
  ${breakpoint('xs')`
    flex-direction:column;
  `}
  ${breakpoint('lg')`
    flex-direction:row;
  `}
`;

const FooterContent = styled.div`
  ${breakpoint('xs')`
    padding-right: 0;
    padding-left: 0;
  `}
  ${breakpoint('lg')`
    padding-right: 35px;
    padding-left: 15px;
  `}
`;

const BannerButtonAccept = styled.button`
  min-height: initial;
  background-color: #b59660 !important;
  border-style: none;
  border-color: #b59660 !important;
  color: #ffffff !important;
  height: fit-content;
  padding: 8px;
  min-width: 90px;
  font-size: 14px;
  font-weight: 400 !important;
  cursor: pointer;
  outline: none !important;
  ${breakpoint('xs')`
    width: 100%;
    margin-top: 10px;
  `}
  ${breakpoint('lg')`
    width: 140px !important;
    margin-top: 0;
    margin-right: 10px;
  `}
`;

const BannerButtonRefuse = styled.button`
  min-height: initial;
  background-color: #b59660 !important;
  border-style: none;
  border-color: #b59660 !important;
  color: #ffffff !important;
  height: fit-content;
  padding: 8px;
  min-width: 90px;
  font-size: 14px;
  font-weight: 400 !important;
  cursor: pointer;
  outline: none !important;
  ${breakpoint('xs')`
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  `}
  ${breakpoint('lg')`
    width: 100px;
    margin-top: 0;
    margin-left: 10px;
  `}
`;
// #b59660
// #004c7e
type NewsletterProps = FormProps & {
  data: any;
  theme: any;
  form: Form;
};

interface NewsletterState {
  loading: boolean;
  feedbackMessage: string;
  email: string;
}

class NewsletterWrapper extends React.Component<
  NewsletterProps,
  NewsletterState
> {
  state = {
    loading: false,
    feedbackMessage: '',
    email: '',
  };

  resetFields = () => {
    this.setState({ email: '' });
  };

  clearFeedbackMessage = () => {
    this.setState({ feedbackMessage: '' });
  };

  buildEmailHtml = values => `<html>
<head>
</head>
<body>
<table id="box" cellpadding="0" cellspacing="0" border="0" align="center" style="width: 100%; border-collapse: collapse; margin: 50px auto; font-family: 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif; font-size: 15px;" bgcolor="white" width="100%">
  <tbody>
    <tr>
      <td>
        <table id="summary-box" style="border-spacing: 20px 0;">
          <tr>
            <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Email</p><p style="font-weight: bold; font-size: 14px;"></p></td>
            <td class="property-value"><p style="font-size: 13px;">${get(
              values,
              'email',
              'n/a'
            )}</p></td>
          </tr>
        </table>
      </td>
    </tr>
  </tbody>
</table>
</body>
</html>`;

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true, feedbackMessage: '' });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const destinations = get(this.props.data, 'destinations');
          const subject =
            get(this.props.data, 'subject') +
            ' - ' +
            get(values, 'email', 'n/a');

          await sendMail(
            destinations,
            subject,
            this.buildEmailHtml(values),
            'Somebody subscribed to a newsletter...'
          );

          this.resetFields();

          const successMessage = get(this.props.data, 'successMessage.html');
          if (successMessage) {
            this.setState({ feedbackMessage: successMessage });
          }
        } catch (e) {
          const errorMessage = get(this.props.data, 'errorMessage.html');
          if (errorMessage) {
            this.setState({ feedbackMessage: errorMessage });
          }
        }
      }

      this.setState({ loading: false });
    });
  };

  render() {
    const { data, theme, form } = this.props;
    const { loading, email, feedbackMessage } = this.state;

    const newsletterTitle = get(data, 'title', '');
    const placeholder = get(data, 'email', '');

    return (
      <NewsletterContainer>
        {newsletterTitle && (
          <NewsletterTitle level={4} color={theme.color.secondary.light}>
            {newsletterTitle}
          </NewsletterTitle>
        )}
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {form.getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Please input your email!' },
                { pattern: EMAIL_REGEX, message: 'Your email is invalid!' },
              ],
            })(
              <NewsletterTextBoxContainer>
                <Input
                  disabled={loading}
                  placeholder={placeholder}
                  value={email}
                  placeholderColor={theme.color.secondary.light}
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <ArrowButton htmlType="submit" disabled={loading}>
                  {loading ? <Icon type="loading" /> : <Icon type="right" />}
                </ArrowButton>
              </NewsletterTextBoxContainer>
            )}
          </FormItem>
        </Form>
        {feedbackMessage && (
          <Text small={true}>
            <span dangerouslySetInnerHTML={{ __html: feedbackMessage }} />
          </Text>
        )}
      </NewsletterContainer>
    );
  }
}

const NewsletterForm = Form.create<NewsletterProps>({
  name: 'newsletter-form',
})(NewsletterWrapper);
const NewsletterWrapperWithTheme = withTheme(NewsletterForm);

const InnerFooter = ({ data, currentPage, theme }) => {
  const [showBanner, setShowBanner] = useState(typeof window !== `undefined` &&
    localStorage.getItem("banner_accept") !== "true");
  const menuItems = get(data, 'node.data.menuItems');
  const copyright = get(data, 'node.data.copyright');
  const bannerContentText = get(data, 'node.data.banner_content_text');
  const bannerButtonTextAccept = get(data, 'node.data.banner_button_accept_text', "Accept");
  // const bannerButtonTextRefuse = get(data, 'node.data.banner_button_decline_text', "Decline");
  const bannerLinkText = get(data, 'node.data.banner_link_text');
  const bannerLink = getInternalUrl(
    get(
      data,
      'node.data.banner_link.document[0].data.url',
      ''
    ),
    currentPage.lang
  );

  const socialMediaTitle = get(data, 'node.data.socialMediaTitle');
  const socialMedias = get(data, 'node.data.socialMedias');
  const newsletter = get(data, 'node.data.newsletter.document[0].data');

  return (
    <Container>
      <Row type="flex" justify="end" align="top" gutter={36}>
        <Col xs={24} md={12}>
          <MenuContainer>
            {Array.isArray(menuItems) &&
              menuItems.length > 0 &&
              menuItems.map(m => {
                const id = get(m, 'menuItem.id');
                const lang = get(m, 'menuItem.lang');
                const title = get(m, 'menuItem.document[0].data.title', '');
                const link = get(
                  m,
                  'menuItem.document[0].data.link.document[0].data.url',
                  '/'
                );
                return (
                  <LinkMenu
                    key={`menu_${id}`}
                    to={getInternalUrl(link, lang)}
                    isCurrentPage={link === currentPage.currentPage}
                  >
                    {title}
                  </LinkMenu>
                );
              })}
          </MenuContainer>
        </Col>
        <Col xs={24} md={12}>
          <Row type="flex" justify="end" align="top">
            <Col xs={24} md={24} lg={10}>
              <SocialMediaContainer>
                {socialMediaTitle && (
                  <SocialMediaTitle
                    level={4}
                    color={theme.color.secondary.light}
                  >
                    {socialMediaTitle}
                  </SocialMediaTitle>
                )}
                {Array.isArray(socialMedias) && socialMedias.length > 0 && (
                  <SocialMediaLinkContainer>
                    {socialMedias.map((s, idx) => {
                      const id = get(s, 'socialMedia.id');
                      const name = get(
                        s,
                        'socialMedia.document[0].data.name',
                        ''
                      );
                      const link = get(
                        s,
                        'socialMedia.document[0].data.link.url',
                        ''
                      );
                      const target = get(
                        s,
                        'socialMedia.document[0].data.link.target',
                        ''
                      );
                      const icon = get(s, 'socialMedia.document[0].data.icon');

                      if (!name || !icon) return null;

                      return (
                        <SocialMediaLink
                          key={`social_media_${id}_${idx}`}
                          to={link}
                          target={target}
                        >
                          <SocialMediaIcon alt={get(icon, 'alt')} data={icon} />
                        </SocialMediaLink>
                      );
                    })}
                  </SocialMediaLinkContainer>
                )}
              </SocialMediaContainer>
            </Col>
            <Col xs={24} md={24} lg={14}>
              {newsletter && <NewsletterWrapperWithTheme data={newsletter} />}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        {copyright && (
          <CopyrightText small color={theme.color.secondary.light}>
            {copyright}
          </CopyrightText>
        )}
      </Row>
      {showBanner && (
        <Row type="flex">
          <FooterBanner>
              <FooterContent>
                {bannerContentText}{' '}
                <Link href={bannerLink}>
                  {bannerLinkText}
                </Link>
              </FooterContent>
                <BannerButtonAccept
                  type="primary"
                  size="large"
                  id="footer_btn_accept"
                  onClick={(e) => {
                    localStorage.setItem("banner_accept", "true");
                    setShowBanner(false);
                }}
              >
                {bannerButtonTextAccept}
              </BannerButtonAccept>
              {/* <BannerButtonRefuse
                  type="primary"
                  size="large"
                  id="footer_btn_refuse"
                  onClick={(e) => {
                    // localStorage.setItem("banner_accept", "true");
                    setShowBanner(false);
                }}
              >
                {bannerButtonTextRefuse}
              </BannerButtonRefuse> */}
          </FooterBanner>
        </Row>
        )
      }
    </Container>
  );
};

const InnerFooterWithTheme = withTheme(InnerFooter);

const Footer = (currentPage, lang) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const localizedData = find(
          get(data, 'allPrismicFooter.edges', []),
          content => currentPage.lang === get(content, 'node.lang')
        );
        return (
          <InnerFooterWithTheme
            currentPage={currentPage}
            data={localizedData}
          />
        );
      }}
    />
  );
};

export default Footer;
export const query = graphql`
  {
    allPrismicFooter {
      ...Footer
    }
  }
`;
