import React, { Component } from 'react';
import get from 'lodash/get';
import { Introduction } from './';

interface Props {
  data: any;
}
class PageIntroduction extends Component<Props, any> {
  render() {
    const { data } = this.props;
    const title = get(data, 'primary.title', '');
    const description = get(data, 'primary.description', '');
    return (
      <Introduction title={title.toUpperCase()} description={description} />
    );
  }
}

export default PageIntroduction;
