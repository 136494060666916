import { Typography } from 'antd';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { getMaxSize, getSize } from '../../utils';

// Responsive font-size component
const Title = styled(Typography.Title)`
  font-size: ${props => getSize(props.size || props.level)} !important;
  font-weight: 400 !important;
  letter-spacing: 1.5px;
  ${breakpoint('xl')`
    font-size: ${props => getMaxSize(props.size || props.level)}px !important;
  `}

  color: ${props =>
    props.color ? props.color : props.theme.color.text.main} !important;
`;

export default Title;
