import React, { Component } from 'react';
import get from 'lodash/get';
import breakpoint from 'styled-components-breakpoint';
import styled, { withTheme } from 'styled-components';
import { Row, Col, Form, message } from 'antd';
import {
  Title,
  Link,
  Input,
  Checkbox,
  InputNumber,
  DatePicker,
  TimePicker,
  Paragraph,
} from './index';
import { sendMail } from '../../utils/sendMail';
import { EMAIL_REGEX, PHONE_REGEX } from '../../constants/pattern';
import moment from 'moment-timezone';
// import moment from 'moment';

import Modal from 'react-modal';

const FormContainer = styled.div`
  width: 100%;
  min-height: 500px;
  padding: 5% 7%;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const FormInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.disabled
      ? props.theme.color.background.black
      : props.theme.color.background.main};
  height: 50px;
  overflow: hidden;
  width: 100%;
  margin-top: 15px;
  margin-right: 15px;
  justify-content: space-between;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  * {
    font-family: ${props => props.theme.fontFamily};
  }
`;

const FormInputContainerWithoutPadding = styled(FormInputContainer)`
  padding: 0px !important;
`;

const FormInputContainerWithoutBG = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  overflow: hidden;
  width: 100%;
  /* padding: 5px; */
  margin-top: 15px;
  margin-right: 15px;
  justify-content: space-between;
  * {
    font-family: ${props => props.theme.fontFamily};
  }
`;

const FormCol = styled(Col)`
  text-align: center;
`;

const SubmitButton = styled.a`
  color: ${props => props.theme.color.text.light};
  font-size: 18px !important;
  text-decoration: underline;
`;

const SubmitButtonRow = styled(Row)`
  margin-top: 10px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`;

interface Props {
  data: any;
  theme: any;
  form: any;
  showBooking: boolean;
  onClose: Function;
  getContainer?: (instance: React.ReactInstance) => HTMLElement;
}

class LimousineForm extends Component<Props, any> {
  limousine: any;

  state = {
    loading: false,
    passenger: [
      {
        firstName: '',
        lastName: '',
      },
    ],
    numberOfPassenger: 1,
    email: '',
    tel: '',
    pickupStatus: false,
    pickupLocation: '',
    pickupDate: moment(),
    pickupTime: moment(),
    dropoffStatus: false,
    dropoffLocation: '',
    dropoffDate: moment(),
    dropoffTime: moment(),
    dateErrorMessage: '',
  };

  onClose() {
    this.clearForm();
    this.props.onClose();
  }

  buildEmailHtml = values => {
    let HTML = `<html>
    <head>
    </head>
    <body>
    <table id="box" cellpadding="0" cellspacing="0" border="0" align="center" style="width: 100%; border-collapse: collapse; margin: 50px auto; font-family: 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif; font-size: 15px;" bgcolor="white" width="100%">
      <tbody>
        <tr>
          <td>
            <table id="summary-box" style="border-spacing: 20px 0;">
            <tr>
              <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Passenger</p><p style="font-weight: bold; font-size: 14px;"></p></td>
              <td class="property-value"><p style="font-size: 13px;">${values
                .passenger[0].firstName +
                ' ' +
                values.passenger[0].lastName}</p></td>
            </tr>
            <tr>
              <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Number Of Passenger</p><p style="font-weight: bold; font-size: 14px;"></p></td>
              <td class="property-value"><p style="font-size: 13px;">${
                values.numberOfPassenger
              }</p></td>
            </tr>
            <tr>
              <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Email</p><p style="font-weight: bold; font-size: 14px;"></p></td>
              <td class="property-value"><p style="font-size: 13px;">${
                values.email
              }</p></td>
            </tr>
            <tr>
              <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Tel No.</p><p style="font-weight: bold; font-size: 14px;"></p></td>
              <td class="property-value"><p style="font-size: 13px;">${
                values.tel
              }</p></td>
            </tr>
            ${
              values.pickupStatus
                ? `
            <tr>
                <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Pickup Status</p><p style="font-weight: bold; font-size: 14px;"></p></td>
                <td class="property-value"><p style="font-size: 13px;">YES</p></td>
            </tr>
            <tr>
                <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Pickup Location</p><p style="font-weight: bold; font-size: 14px;"></p></td>
                <td class="property-value"><p style="font-size: 13px;">${
                  values.pickupLocation ? values.pickupLocation : '-'
                }</p></td>
            </tr>
            <tr>
                <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Pickup Date</p><p style="font-weight: bold; font-size: 14px;"></p></td>
                <td class="property-value"><p style="font-size: 13px;">${
                  values.pickupDate ? values.pickupDate : '-'
                }</p></td>
            </tr>
            <tr>
                <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Pickup Time</p><p style="font-weight: bold; font-size: 14px;"></p></td>
                <td class="property-value"><p style="font-size: 13px;">${
                  values.pickupTime ? values.pickupTime : '-'
                }</p></td>
            </tr>
          `
                : `
          <tr>
            <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Pickup Status</p><p style="font-weight: bold; font-size: 14px;"></p></td>
            <td class="property-value"><p style="font-size: 13px;">NO</p></td>
          </tr>`
            }
            ${
              values.dropoffStatus
                ? `
            <tr>
                <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Drop-off Status</p><p style="font-weight: bold; font-size: 14px;"></p></td>
                <td class="property-value"><p style="font-size: 13px;">YES</p></td>
            </tr>
            <tr>
                <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Drop-off Location</p><p style="font-weight: bold; font-size: 14px;"></p></td>
                <td class="property-value"><p style="font-size: 13px;">${
                  values.dropoffLocation ? values.dropoffLocation : '-'
                }</p></td>
            </tr>
            <tr>
                <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Drop-off Date</p><p style="font-weight: bold; font-size: 14px;"></p></td>
                <td class="property-value"><p style="font-size: 13px;">${
                  values.dropoffDate ? values.dropoffDate : '-'
                }</p></td>
            </tr>
            <tr>
                <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Drop-off Time</p><p style="font-weight: bold; font-size: 14px;"></p></td>
                <td class="property-value"><p style="font-size: 13px;">${
                  values.dropoffTime ? values.dropoffTime : '-'
                }</p></td>
            </tr>
          `
                : `
          <tr>
            <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Drop-off Status</p><p style="font-weight: bold; font-size: 14px;"></p></td>
            <td class="property-value"><p style="font-size: 13px;">NO</p></td>
          </tr>`
            }`;

    HTML += `</table>
        </td>
      </tr>
    </tbody>
  </table>
  </body>
  </html>`;
    return HTML;
  };

  async onSubmit() {
    this.setState({ loading: true });

    // If there are 2 input for pickup and drop-off date check if pickup is before dropoff date
    if (!this.validatePickupDropoffDate()) {
      this.setState({
        loading: false,
        dateErrorMessage: 'Invalid date Pickup should be before Drop-off',
      });
      return;
    } else {
      this.setState({ dateErrorMessage: '' });
    }

    const {
      passenger,
      numberOfPassenger,
      email,
      tel,
      pickupStatus,
      pickupLocation,
      pickupDate,
      pickupTime,
      dropoffStatus,
      dropoffLocation,
      dropoffDate,
      dropoffTime,
    } = this.state;

    const { data } = this.props;

    this.props.form.validateFields(async (err, values) => {
      try {
        if (err) {
          throw new Error('Sent message fail');
        }
        const destinations = get(data, 'primary.bookingEmailDestination');
        const cc = get(data, 'primary.bookingEmailCc');
        const subject =
          get(data, 'primary.bookingEmailSubject') + ' - ' + email;

        await sendMail(
          destinations,
          subject,
          this.buildEmailHtml({
            passenger,
            numberOfPassenger,
            email,
            tel,
            pickupStatus,
            pickupLocation,
            pickupDate,
            pickupTime,
            dropoffStatus,
            dropoffLocation,
            dropoffDate,
            dropoffTime,
          }),
          `${get(this.state, 'email')} booking via web...`,
          null,
          cc
        );

        this.onClose();

        message.success(get(data, 'primary.onSuccessMessage'));
      } catch (error) {
        message.error(get(data, 'primary.onErrorMessage'));
        this.setState({ loading: false });
      }
    });
  }

  clearForm() {
    this.setState({
      loading: false,
      passenger: [
        {
          firstName: '',
          lastName: '',
        },
      ],
      numberOfPassenger: 1,
      email: '',
      tel: '',
      pickupStatus: false,
      pickupLocation: '',
      pickupDate: moment(),
      pickupTime: moment(),
      dropoffStatus: false,
      dropoffLocation: '',
      dropoffDate: moment(),
      dropoffTime: moment(),
      dateErrorMessage: '',
    });
    this.props.form.resetFields();
  }

  generatePassengerForm(passenger, numberOfPassenger) {
    return passenger.map((people, index) => (
      <Row key={'p-form-' + (index + 1)}>
        <Col md={6}>
          <FormInputContainerWithoutPadding>
            <InputNumber
              value={numberOfPassenger}
              onKeyDown={e => e.preventDefault()}
              min={1}
              formatter={value => `${value}  Passenger`}
              parser={value => value.replace('  Passenger', '')}
              onChange={value => {
                if (Number.isInteger(value) == true) {
                  this.setState({ numberOfPassenger: value });
                }
              }}
              type="text"
              pattern="[0-9]"
            />
          </FormInputContainerWithoutPadding>
        </Col>
        <Col md={{ span: 8, offset: 1 }}>
          <FormItem>
            <FormInputContainer>
              {this.props.form.getFieldDecorator('firstname', {
                rules: [
                  { required: true, message: 'Please input your first name!' },
                  { min: 3, message: 'Your name is too short' },
                ],
              })(
                <Input.Container>
                  <Input
                    placeholder={get(
                      this.props.data,
                      'primary.bookingFirstNamePlaceholder',
                      ''
                    )}
                    disabled={this.state.loading}
                    value={people.firstName}
                    onChange={e =>
                      this.updatePassenger(
                        index,
                        e.target.value,
                        people.lastName
                      )
                    }
                  />
                </Input.Container>
              )}
            </FormInputContainer>
          </FormItem>
        </Col>
        <Col md={{ span: 8, offset: 1 }}>
          <FormItem>
            <FormInputContainer>
              {this.props.form.getFieldDecorator('lastname', {
                rules: [
                  { required: true, message: 'Please input your last name!' },
                  { min: 3, message: 'Your name is too short' },
                ],
              })(
                <Input.Container>
                  <Input
                    placeholder={get(
                      this.props.data,
                      'primary.bookingLastNamePlaceholder',
                      ''
                    )}
                    disabled={this.state.loading}
                    value={people.lastName}
                    onChange={e =>
                      this.updatePassenger(
                        index,
                        people.firstName,
                        e.target.value
                      )
                    }
                  />
                </Input.Container>
              )}
            </FormInputContainer>
          </FormItem>
        </Col>
      </Row>
    ));
  }

  updatePassenger(index, firstName, lastName) {
    const newArray = this.state.passenger.slice();
    newArray[index] = {
      firstName,
      lastName,
    };
    this.setState({ passenger: newArray });
  }

  validatePickupDropoffDate() {
    const { pickupDate, dropoffDate, dropoffStatus } = this.state;
    if (!pickupDate || !dropoffDate) return true;
    else if (
      dropoffStatus &&
      moment(pickupDate, 'DD/MM/YYYY').isAfter(
        moment(dropoffDate, 'DD/MM/YYYY')
      )
    ) {
      return false;
    }
    return true;
  }

  render() {
    const { data, theme, form, showBooking, getContainer } = this.props;

    if (typeof document !== `undefined`) {
      // Modal position fixed in iOS
      if (showBooking) {
        // When the modal is shown, we want a fixed body
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;
      } else {
        // When the modal is hidden...
        const top = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(
          0,
          parseInt(document.body.style.top.toString() || '0') * -1
        );
      }
    }

    const {
      loading,
      passenger,
      numberOfPassenger,
      email,
      tel,
      pickupStatus,
      pickupLocation,
      pickupDate,
      pickupTime,
      dropoffStatus,
      dropoffLocation,
      dropoffDate,
      dropoffTime,
      dateErrorMessage,
    } = this.state;
    const descriptionHtml = get(
      data,
      'primary.bookingFormDescription.html',
      ''
    );
    return (
      <div>
        {/* POP UP MODAL FOR BOOKING */}
        <Modal
          isOpen={showBooking}
          onRequestClose={this.onClose.bind(this)}
          contentLabel="Limousine Request Booking"
          className="limousine-modal"
          overlayClassName="limousine-overlay"
          closeTimeoutMS={200}
        >
          {/* Close Icon */}
          <button
            type="button"
            aria-label="Close"
            className="ant-modal-close"
            onClick={() => this.props.onClose()}
          >
            <span className="ant-modal-close-x">
              <i
                aria-label="icon: close"
                className="anticon anticon-close ant-modal-close-icon"
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  className=""
                  data-icon="close"
                  width="2em"
                  height="2em"
                  fill="currentColor"
                  aria-hidden="true"
                  style={{ margin: '10px' }}
                >
                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
                </svg>
              </i>
            </span>
          </button>
          <FormContainer>
            <Title color={theme.color.primary.light}>
              {get(data, 'primary.bookingFormTitle', '')}
            </Title>
            {descriptionHtml && (
              <Paragraph>
                <span
                  style={{ textAlign: 'left' }}
                  dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                />
              </Paragraph>
            )}

            <FormCol span={24}>
              <Form>
                {this.generatePassengerForm(passenger, numberOfPassenger)}
                <Row>
                  <Col md={12}>
                    <FormItem>
                      <FormInputContainer>
                        {form.getFieldDecorator('email', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input your email!',
                            },
                            {
                              pattern: EMAIL_REGEX,
                              message: 'Your email is invalid!',
                            },
                          ],
                        })(
                          <Input.Container>
                            <Input
                              type="email"
                              placeholder={get(
                                data,
                                'primary.bookingEmailPlaceholder',
                                ''
                              )}
                              disabled={loading}
                              value={email}
                              onChange={e =>
                                this.setState({ email: e.target.value })
                              }
                              required
                            />
                          </Input.Container>
                        )}
                      </FormInputContainer>
                    </FormItem>
                  </Col>
                  <Col md={{ span: 11, offset: 1 }}>
                    <FormItem>
                      <FormInputContainer>
                        {form.getFieldDecorator('phone', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input your phone!',
                            },
                            {
                              min: 9,
                              message: 'Your phone number is too short',
                            },
                            {
                              pattern: PHONE_REGEX,
                              message: 'Your phone number is invalid!',
                            },
                          ],
                        })(
                          <Input.Container>
                            <Input
                              placeholder={get(
                                data,
                                'primary.bookingTelPlaceholder',
                                ''
                              )}
                              disabled={loading}
                              value={tel}
                              onChange={e =>
                                this.setState({ tel: e.target.value })
                              }
                              required
                            />
                          </Input.Container>
                        )}
                      </FormInputContainer>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <FormInputContainerWithoutBG>
                      <Checkbox
                        checked={pickupStatus}
                        onChange={e =>
                          this.setState({ pickupStatus: !pickupStatus })
                        }
                      >
                        {get(data, 'primary.bookingPickUpText', '')}
                      </Checkbox>
                    </FormInputContainerWithoutBG>
                  </Col>
                  <Col md={{ span: 7, offset: 0 }}>
                    <FormInputContainer disabled={!pickupStatus || loading}>
                      <Input
                        placeholder={get(
                          data,
                          'primary.bookingLocationPlaceholder',
                          ''
                        )}
                        disabled={!pickupStatus || loading}
                        value={pickupLocation}
                        onChange={e =>
                          this.setState({ pickupLocation: e.target.value })
                        }
                        required
                      />
                    </FormInputContainer>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }}>
                    <FormItem>
                      <FormInputContainer disabled={!pickupStatus || loading}>
                        <DatePicker
                          placeholder={get(
                            data,
                            'primary.bookingDatePlaceholder',
                            ''
                          )}
                          disabled={!pickupStatus || loading}
                          onChange={(date, dateString) =>
                            this.setState({ pickupDate: dateString })
                          }
                          format="DD/MM/YYYY"
                          defaultValue={pickupDate}
                        />
                      </FormInputContainer>
                    </FormItem>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }}>
                    <FormItem>
                      <FormInputContainer disabled={!pickupStatus || loading}>
                        <TimePicker
                          disabled={!pickupStatus || loading}
                          onChange={(time, timeString) =>
                            this.setState({ pickupTime: timeString })
                          }
                          placeholder={get(
                            data,
                            'primary.bookingTimePlaceholder',
                            ''
                          )}
                          minuteStep={10}
                          format="HH:mm"
                          defaultValue={pickupTime}
                        />
                      </FormInputContainer>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <FormInputContainerWithoutBG>
                      <Checkbox
                        checked={dropoffStatus}
                        onChange={e =>
                          this.setState({ dropoffStatus: !dropoffStatus })
                        }
                      >
                        {get(data, 'primary.bookingDropOffText', '')}
                      </Checkbox>
                    </FormInputContainerWithoutBG>
                  </Col>
                  <Col md={{ span: 7, offset: 0 }}>
                    <FormInputContainer disabled={!dropoffStatus || loading}>
                      <Input
                        placeholder={get(
                          data,
                          'primary.bookingLocationPlaceholder',
                          ''
                        )}
                        disabled={!dropoffStatus || loading}
                        value={dropoffLocation}
                        onChange={e =>
                          this.setState({ dropoffLocation: e.target.value })
                        }
                        required
                      />
                    </FormInputContainer>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }}>
                    <FormItem
                      validateStatus={dateErrorMessage ? 'error' : 'success'}
                      help={dateErrorMessage}
                    >
                      <FormInputContainer disabled={!dropoffStatus || loading}>
                        <DatePicker
                          placeholder={get(
                            data,
                            'primary.bookingDatePlaceholder',
                            ''
                          )}
                          disabled={!dropoffStatus || loading}
                          onChange={(date, dateString) =>
                            this.setState({ dropoffDate: dateString })
                          }
                          format="DD/MM/YYYY"
                          defaultValue={dropoffDate}
                        />
                      </FormInputContainer>
                    </FormItem>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }}>
                    <FormInputContainer disabled={!dropoffStatus || loading}>
                      <TimePicker
                        placeholder={get(
                          data,
                          'primary.bookingTimePlaceholder',
                          ''
                        )}
                        disabled={!dropoffStatus || loading}
                        onChange={(time, timeString) =>
                          this.setState({ dropoffTime: timeString })
                        }
                        minuteStep={10}
                        format="HH:mm"
                        defaultValue={dropoffTime}
                      />
                    </FormInputContainer>
                  </Col>
                </Row>
                <SubmitButtonRow>
                  <SubmitButton
                    onClick={this.onSubmit.bind(this)}
                    // disabled={this.validateForm()}
                  >
                    {get(data, 'primary.bookingSendText', '')}
                  </SubmitButton>
                </SubmitButtonRow>
              </Form>
            </FormCol>
          </FormContainer>
        </Modal>
      </div>
    );
  }
}

const WrappedLimousineForm = Form.create<Props>({
  name: 'request-limousine-form',
})(LimousineForm);

export default withTheme(WrappedLimousineForm);
