import React from 'react';
import get from 'lodash/get';
import GatsbyImage from 'gatsby-image';

const Image = ({ data, index = '', ...props }) => {
  if (get(data, 'localFile.childImageSharp', false)) {
    const propsObject = {
      fluid: get(data, 'localFile.childImageSharp.fluid'),
      fixed: get(data, 'localFile.childImageSharp.fixed'),
      alt: get(data, 'alt', 'Image'),
      ...props,
    };

    if (index) {
      return <GatsbyImage key={index} {...propsObject} />;
    }

    return <GatsbyImage {...propsObject} />;
  }

  const propsObject = {
    src: get(data, 'url'),
    alt: get(data, 'alt', 'Image'),
    ...props,
  };

  if (get(data, 'localFile.publicURL', false)) {
    propsObject.src = get(data, 'localFile.publicURL');
  }

  if (!index) {
    return <img key={index} {...propsObject} />;
  }

  return <img {...propsObject} />;
};

export default Image;
