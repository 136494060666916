import { Typography } from 'antd';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { getMaxSize, getSize } from '../../utils';

// Responsive: 14 to 18px
const Text = styled(Typography.Text)`
  font-size: ${props => {
    if (props.size) return getSize(props.size);
    return props.small
      ? 'calc(0.875rem + ((1vw - 4.8px) * 0.2778))'
      : 'calc(0.875rem + ((1vw - 4.8px) * 0.5556))';
  }} !important;
  ${breakpoint('xl')`
    font-size: ${props => {
      if (props.size) return getMaxSize(props.size);
      return props.small ? '15px' : '18px';
    }} !important;
    ${props => props.size && 'font-weight: 400 !important;'}
  `}
  color: ${props =>
    props.color ? props.color : props.theme.color.text.main} !important;
`;

export default Text;
