import React, { ReactNode } from 'react';
import { default as ReactSlider } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type Props = {
  children: ReactNode;
  settings: any;
};

const Slider = React.forwardRef<any, Props>((props, ref) => (
  <ReactSlider ref={ref} {...props.settings || {}}>
    {props.children}
  </ReactSlider>
));

export default Slider;
