import { startsWith } from 'lodash';
import { mapLanguage } from './mapLanguage';

export const getInternalUrl = (url, lang = '') => {
  if (
    startsWith(url, '/th/')
    || startsWith(url, '/jp/')
    || startsWith(url, '/en/')
    || startsWith(url, 'www')
    || startsWith(url, 'http')
    || startsWith(url, 'https')
  ) {
    return url;
  }

  if (typeof url === 'undefined' || !url) {
    return '/404';
  }

  return `/${mapLanguage(lang)}/${url.replace(/^\//, '')}`;
};
