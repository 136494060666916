import React from 'react';
import styled, { withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import get from 'lodash/get';
import { Row, Col } from 'antd';
import {
  SectionContainer,
  Title,
  Paragraph,
  Link,
  Text,
} from '../common/components';
import * as flowerThickGold from '../assets/flower-thick-gold.svg';
import CareersForm from './CareersForm';

interface Props {
  data: any;
  theme: any;
}

const Container = styled(SectionContainer)`
  margin-top: 35px;
  margin-bottom: 4rem;
  padding-left: 40px;
  padding-right: 40px;

  ${breakpoint('xl')`
    padding-left: 0;
    padding-right: 0;
  `}
`;

const InnerContainer = styled(Row)`
  text-align: left;
`;

const ContentTitle = styled(Title)`
  text-transform: uppercase;
  margin-bottom: 25px !important;

  ${breakpoint('md')`
    margin-bottom: 45px !important;
  `}
`;

const JobPositionList = styled.ul`
  list-style: none;
  padding-inline-start: 0;
`;

const JobPositionListItem = styled.li`
  padding: 0 0 1em 34px;
  background: url(${flowerThickGold}) no-repeat;
  background-size: 21px;

  & + & {
    margin-top: 20px;

    ${breakpoint('md')`
      margin-top: 40px;
    `}
  }
`;

const JobPositionLinkText = styled(Text)`
  text-transform: uppercase;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.color.primary.main} !important;
  }
`;

const JobPosition = ({ title, description, link, linkText, linkTarget }) => (
  <JobPositionListItem>
    <div>{title && <Title level={3}>{title}</Title>}</div>
    {description && (
      <Paragraph small>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </Paragraph>
    )}
    <div>
      {link && linkText && (
        <Link to={link} target={linkTarget}>
          <JobPositionLinkText small>{linkText}</JobPositionLinkText>
        </Link>
      )}
    </div>
  </JobPositionListItem>
);

class CareersJobOpenings extends React.Component<Props, any> {
  render() {
    const { data, theme } = this.props;

    const title = get(data, 'primary.title', '');
    const jobPositions = get(data, 'items', []);
    const form = get(data, 'primary.form.document[0].data');

    return (
      <Container>
        <InnerContainer type="flex">
          <Col xs={24} md={form ? 12 : 24}>
            {title && (
              <ContentTitle level={2} color={theme.color.primary.light}>
                {title}
              </ContentTitle>
            )}
            {jobPositions.length > 0 && (
              <JobPositionList>
                {jobPositions.map((job, idx) => {
                  const id = get(job, 'jobPosition.id', `job_${idx}`);
                  const title = get(
                    job,
                    'jobPosition.document[0].data.title',
                    ''
                  );
                  const description = get(
                    job,
                    'jobPosition.document[0].data.description.html',
                    ''
                  );
                  const link = get(
                    job,
                    'jobPosition.document[0].data.file.url',
                    ''
                  );
                  const linkText = get(
                    job,
                    'jobPosition.document[0].data.fileButtonText',
                    ''
                  );
                  return (
                    <JobPosition
                      key={id}
                      title={title}
                      description={description}
                      link={link}
                      linkTarget="_blank"
                      linkText={linkText}
                    />
                  );
                })}
              </JobPositionList>
            )}
          </Col>
          <Col
            xs={form ? 24 : 0}
            md={{ span: form ? 11 : 0, offset: form ? 1 : 0 }}
            lg={{ span: form ? 10 : 0, offset: form ? 2 : 0 }}
          >
            <CareersForm data={form} />
          </Col>
        </InnerContainer>
      </Container>
    );
  }
}

export default withTheme(CareersJobOpenings);
