import keys from 'lodash/keys';
import { LANGUAGE } from '../constants/language';

export const mapLanguage = (string) => {
  if (keys(LANGUAGE).indexOf(string) !== -1) {
    return LANGUAGE[string];
  }

  return 'en';
};
