import React from 'react';
import { get } from 'lodash';
import styled, { withTheme, css } from 'styled-components';
import Img from 'gatsby-image';
import breakpoint from 'styled-components-breakpoint';
import { Row, Col } from 'antd';
import scrollToComponent from 'react-scroll-to-component';
import {
  ImageSlider,
  SectionContainer,
  Title,
  Paragraph,
  FlowerPattern,
  Text,
  Link,
} from '.';
import * as flower3Black from '../../assets/flower-3-black.svg';

interface Props {
  theme: any;
  layout: 'primary-left' | 'primary-right';
  anchorHash?: string;
  primary?: {
    title?: string;
    description?: string;
    readmoreUrl?: string;
    readmoreUrlTarget?: string;
    readmoreText?: string;
    achorHash?: string;
  };
  secondary?: {
    type: 'card' | 'sliders';
    title?: string;
    description?: string;
    additional?: string;
    achorHash?: string;
  };
  images?: {
    imageType: 'primary' | 'secondary';
    image: { alt?: string } & ({ url: string } | { localFile: any });
  }[];
}

const Container = styled(SectionContainer)`
  margin-top: 35px;
  margin-bottom: 2rem;
  margin-left: 40px;
  margin-right: 40px;
`;

const BoxContainer = styled(Col)`
  height: auto;
  text-align: left;
  margin-bottom: 20px;
  & + & {
    margin-top: 20px;
  }

  ${breakpoint('lg')`
    ${props =>
      props.flex &&
      css`
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
      `}

    text-align: ${props => props.textAlign || 'left'};
    padding-left: ${props => props.paddingLeft || '0'}px !important;

    & + & {
      padding-left: ${props => props.paddingLeft || '20px'} !important;
      margin-top: 0;
    }
  `}
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const GatsbyImage = styled(Img)`
  width: 100%;
  height: auto;
  object-fit: cover;
  /* transition: transform 0.5s ease;
  :hover {
    transform: scale(1.2);
  } */
`;

const ContentTitle = styled(Title)`
  text-transform: uppercase;
`;

const ContentDescription = styled(Paragraph)`
  // margin-bottom: 0 !important;

  & > span > p:last-child {
    margin-bottom: 0;
  }
`;

const DescriptionReadMore = styled(Text)`
  font-weight: bold;
`;

const CardContainer = styled(Row)`
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.color.primary.main};
  margin-top: 35px;
  padding: 55px 20px;
  width: 100%;

  ${breakpoint('sm')`
    margin-top: 0;
    padding: 45px 40px;
  `}
`;

const Separator = styled.div`
  background-color: ${props => props.theme.color.primary.dark};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;

  ${breakpoint('md')`
    margin-top: 0;
    margin-bottom: 0;
    width: 1px;
    height: 100%;
  `}
`;

const HalfContainer = styled(Row)`
  width: 100%;

  & + & {
    margin-top: 35px;
  }

  & + ${CardContainer} {
    margin-top: 35px;
  }

  ${breakpoint('lg')`
    & + ${CardContainer} {
      margin-top: 0;
    }

    & + & {
      margin-top: 0;
    }
  `}
`;

const TopLeftFlowerPattern = styled(FlowerPattern)`
  ${breakpoint('sm')`
    right: 87.5%;
    bottom: 81%;
  `}

  ${breakpoint('md')`
    right: 93.5%;
    bottom: 86%;
  `}

  ${breakpoint('lg')`
      right: 90.5%;
      bottom: 78%;
    `}
`;

const TopRightFlowerPattern = styled(FlowerPattern)`
  ${breakpoint('sm')`
      right: -2%;
      bottom: 77%;
    `}

  ${breakpoint('lg')`
      right: 0;
      bottom: 74%;
    `}
`;

const TopRightSmallFlowerPattern = styled(FlowerPattern)`
  ${breakpoint('sm')`
      right: 15%;
      bottom: 80%;
    `}

  ${breakpoint('md')`
    right: 9%;
    bottom: 88%;
  `}

  ${breakpoint('lg')`
      right: 15%;
      bottom: 80%;
    `}
`;

class StepSlider extends React.Component<Props, any> {
  prices: any;
  secondary: any;
  primary: any;

  componentDidMount() {
    const { primary, secondary } = this.props;
    if (this.props.anchorHash && this.props.anchorHash === '#prices') {
      try {
        setTimeout(() => {
          scrollToComponent(this.prices, {
            offset: -50,
            align: 'top',
            duration: 2000,
            ease: 'linear',
          });
        }, 500);
      } catch (e) {}
    }

    if (
      this.props.anchorHash &&
      this.props.anchorHash === secondary.achorHash
    ) {
      try {
        setTimeout(() => {
          scrollToComponent(this.secondary, {
            offset: -140,
            align: 'top',
            duration: 2000,
            ease: 'linear',
          });
        }, 500);
      } catch (e) {}
    }
    if (this.props.anchorHash && this.props.anchorHash === primary.achorHash) {
      try {
        setTimeout(() => {
          scrollToComponent(this.primary, {
            offset: -140,
            align: 'top',
            duration: 2000,
            ease: 'linear',
          });
        }, 500);
      } catch (e) {}
    }
  }

  renderImage = (type = 'primary') => ({ image }, idx) => {
    if (typeof image.localFile !== 'undefined') {
      return (
        <GatsbyImage
          key={idx}
          fluid={get(image, `localFile.childImageSharp.${type}`)}
          alt={get(image, 'alt')}
        />
      );
    }

    return <Image src={get(image, 'url')} alt={get(image, 'alt')} />;
  };

  render() {
    const {
      primary = null,
      secondary = null,
      layout,
      images = [],
      theme,
    } = this.props;

    const isPrimaryLeft = layout === 'primary-left';
    const primaryImages =
      images.length > 0
        ? images.filter(
            o => typeof o.imageType !== 'undefined' && o.imageType === 'primary'
          ) || []
        : [];
    const secondaryImages =
      images.length > 0
        ? images.filter(
            o =>
              typeof o.imageType !== 'undefined' && o.imageType === 'secondary'
          ) || []
        : [];

    const primaryTitle = get(primary, 'title', '');
    const primaryDescription = get(primary, 'description', '');
    const primaryReadmoreUrl = get(primary, 'readmoreUrl', '');
    const primaryReadmoreUrlTarget = get(primary, 'readmoreUrlTarget', '');
    const primaryReadmoreText = get(primary, 'readmoreText', '');
    const secondaryType = get(secondary, 'type', 'card');
    const secondaryTitle = get(secondary, 'title', '');
    const secondaryDescription = get(secondary, 'description', '');
    const secondaryAdditional = get(secondary, 'additional', '');

    return (
      <Container>
        <Row type="flex">
          <BoxContainer
            xs={{ span: 24, order: 1 }}
            lg={{ span: 12, order: isPrimaryLeft ? 1 : 2 }}
            paddingLeft={isPrimaryLeft ? 0 : 20}
            ref={p => (this.primary = p)}
          >
            {primaryImages.length > 0 && (
              <ImageSlider
                images={primaryImages}
                isHomePage={false}
                forceDisplayArrows
                renderItem={this.renderImage('primary')}
              />
            )}
          </BoxContainer>
          <BoxContainer
            xs={{ span: 24, order: 2 }}
            lg={{ span: 12, order: isPrimaryLeft ? 2 : 1 }}
            paddingLeft={isPrimaryLeft ? 20 : 0}
            flex
          >
            <HalfContainer
              type="flex"
              justify={isPrimaryLeft ? 'start' : 'end'}
            >
              <BoxContainer
                xs={24}
                lg={16}
                textAlign={isPrimaryLeft ? 'left' : 'right'}
              >
                {primaryTitle && (
                  <ContentTitle level={2} size={3}>
                    {primaryTitle}
                  </ContentTitle>
                )}
                {primaryDescription && (
                  <ContentDescription small>
                    <span
                      dangerouslySetInnerHTML={{ __html: primaryDescription }}
                    />
                  </ContentDescription>
                )}
                {primaryReadmoreUrl && (
                  <Link
                    to={primaryReadmoreUrl}
                    target={primaryReadmoreUrlTarget}
                  >
                    <DescriptionReadMore small color={theme.color.text.primary}>
                      {primaryReadmoreText !== ''
                        ? primaryReadmoreText
                        : 'Read More >'}
                    </DescriptionReadMore>
                  </Link>
                )}
              </BoxContainer>
            </HalfContainer>
            {secondaryType === 'sliders' && (
              <HalfContainer
                type="flex"
                justify="center"
                ref={p => (this.secondary = p)}
              >
                <BoxContainer
                  xs={{ span: 24, order: 1 }}
                  lg={{ span: 12, order: isPrimaryLeft ? 1 : 2 }}
                  paddingLeft={isPrimaryLeft ? 0 : 20}
                >
                  {secondaryImages.length > 0 && (
                    <ImageSlider
                      images={secondaryImages}
                      isHomePage={false}
                      arrowSize="small"
                      forceDisplayArrows
                      renderItem={this.renderImage('secondary')}
                    />
                  )}
                </BoxContainer>
                <BoxContainer
                  xs={{ span: 24, order: 1 }}
                  lg={{ span: 12, order: isPrimaryLeft ? 2 : 1 }}
                  paddingLeft={isPrimaryLeft ? 20 : 0}
                  textAlign={isPrimaryLeft ? 'left' : 'right'}
                >
                  {secondaryTitle && (
                    <ContentTitle level={2} size={3}>
                      {secondaryTitle}
                    </ContentTitle>
                  )}
                  {secondaryDescription && (
                    <ContentDescription small>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: secondaryDescription,
                        }}
                      />
                    </ContentDescription>
                  )}
                </BoxContainer>
              </HalfContainer>
            )}
            {secondaryType === 'card' && (
              <CardContainer type="flex" justify="center">
                <TopLeftFlowerPattern
                  forceDisplay
                  src={flower3Black}
                  height="81px"
                  width="auto"
                  right="84.5%"
                  bottom="86%"
                  opacity="0.7"
                  style={{
                    transform: 'scaleX(-1) scale(0.8)',
                  }}
                />
                <FlowerPattern
                  forceDisplay
                  // ref={p => (this.prices = p)}
                  src={flower3Black}
                  height="81px"
                  width="auto"
                  right="-3px"
                  bottom="-8px"
                  opacity="0.7"
                  style={{
                    transform: 'scaleX(-1) rotate(180deg)',
                  }}
                />
                <TopRightFlowerPattern
                  ref={p => (this.prices = p)}
                  forceDisplay
                  src={flower3Black}
                  height="81px"
                  width="auto"
                  right="0"
                  bottom="83%"
                  opacity="0.7"
                  style={{
                    transform: 'scale(1.25)',
                  }}
                />
                <TopRightSmallFlowerPattern
                  forceDisplay
                  src={flower3Black}
                  force
                  height="81px"
                  width="auto"
                  right="23%"
                  bottom="86%"
                  opacity="0.7"
                  style={{
                    transform: 'scale(0.7) rotate(90deg) scaleX(-1)',
                  }}
                />
                <Col span={24}>
                  {secondaryTitle && (
                    <ContentTitle
                      color={theme.color.text.gray}
                      level={3}
                      style={{ marginBottom: '1em' }}
                    >
                      {secondaryTitle}
                    </ContentTitle>
                  )}
                </Col>
                {secondaryDescription && (
                  <Col xs={24} md={secondaryAdditional ? 11 : 12}>
                    <ContentDescription small color={theme.color.text.gray}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: secondaryDescription,
                        }}
                      />
                    </ContentDescription>
                  </Col>
                )}
                {secondaryDescription && secondaryAdditional && (
                  <Col xs={24} md={2}>
                    <Separator />
                  </Col>
                )}
                {secondaryAdditional && (
                  <Col xs={24} md={secondaryDescription ? 11 : 12}>
                    <ContentDescription small color={theme.color.text.gray}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: secondaryAdditional,
                        }}
                      />
                    </ContentDescription>
                  </Col>
                )}
              </CardContainer>
            )}
          </BoxContainer>
        </Row>
      </Container>
    );
  }
}

export default withTheme(StepSlider);
