import React, { Component } from 'react';
// import get from 'lodash/get';
import styled, { withTheme } from 'styled-components';
import { Col } from 'antd';
import { FlowerPattern, Paragraph, Text, SectionContainer, Link } from '.';
import breakpoint from 'styled-components-breakpoint';
import * as flowerGold1 from '../../assets/flower-2-gold.svg';
import * as flowerGold2 from '../../assets/flower-1-gold.svg';

interface Props {
  data: any;
  theme: any;
  title: string;
  description: string;
  descriptionHtml: string;
  link?: string;
  linkText?: string;
  height?: any;
  backgrounds?: any;
}

const DescriptionCol = styled(Col)`
  text-align: center;
  display: flex !important;
  justify-content: center;
`;

const DescriptionTextContainer = styled.div`
  max-width: 900px;
  ${breakpoint('xs')`
    padding:0 30px;
  `}
  ${breakpoint('md')`
    padding:0 60px;
  `}
`;

const DescriptionText = styled(Paragraph)`
  color: ${props => props.theme.color.text.main} !important;
  text-align: center;
`;

const LinkText = styled(Text)`
  color: ${props => props.theme.color.text.main} !important;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`;

const ReadMoreLink = styled(Link)``;

class HomePageIntroduction extends Component<Props, any> {
  render() {
    const {
      title,
      description,
      link,
      linkText,
      theme,
      height,
      backgrounds,
      descriptionHtml,
    } = this.props;
    return (
      <SectionContainer
        title={title}
        backgrounds={
          backgrounds
            ? backgrounds
            : [
                <FlowerPattern
                  key="bg_1"
                  src={flowerGold1}
                  height={'180px'}
                  width={'auto'}
                  right={'94%'}
                  bottom={'20px'}
                  opacity={0.6}
                />,
                <FlowerPattern
                  key="bg_2"
                  src={flowerGold2}
                  height={'180px'}
                  width={'auto'}
                  right={'-10px'}
                  bottom={'25px'}
                  opacity={0.6}
                />,
              ]
        }
        height={height}
      >
        <DescriptionCol xs={24}>
          <DescriptionTextContainer>
            {description && <DescriptionText>{description}</DescriptionText>}
            {descriptionHtml && (
              <DescriptionText>
                <span
                  dangerouslySetInnerHTML={{
                    __html: descriptionHtml,
                  }}
                />
              </DescriptionText>
            )}
          </DescriptionTextContainer>
        </DescriptionCol>
        {link && linkText && (
          <DescriptionCol xs={24}>
            <ReadMoreLink to={link}>
              <LinkText>{linkText}</LinkText>
            </ReadMoreLink>
          </DescriptionCol>
        )}
      </SectionContainer>
    );
  }
}

export default withTheme(HomePageIntroduction);
