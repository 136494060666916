import React from 'react';
import styled, { withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import moment from 'moment';
import Img from 'gatsby-image';
import { get, uniqBy, orderBy, filter, truncate } from 'lodash';
import { Row, Col } from 'antd';
import { Title, Text, Link, Paragraph, FlowerPattern } from '.';
import { getInternalUrl } from '../../utils';

import flowerGold1 from '../../assets/flower-3-gold.svg';
import flowerGold2 from '../../assets/flower-2-gold.svg';

const Container = styled.div`
  position: relative;
  margin-bottom: 3rem;
`;

const ArticleTitle = styled(Title)`
  font-weight: bold !important;
  text-transform: uppercase;
  padding-top: 15px;
`;

const ArticleExtract = styled(Paragraph)``;

const ReadMoreText = styled(Paragraph)`
  font-weight: bold !important;
`;

const DateText = styled(Paragraph)``;

const FiltersCol = styled(Col)`
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
`;

const FilterText = styled(Text)`
  text-align: center;
`;

const FiltersRow = styled(Row)``;

const FilterContainer = styled.div`
  padding: 0 12px;
  :hover {
    cursor: pointer;
  }
`;

const ArticlesRow = styled(Row)``;

const ArticleCol = styled(Col)`
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  margin-bottom: 50px;
  overflow: hidden;
`;

const ArticlePreviewImage = styled(Img)`
  width: 100% !important;
  /* height: 100% !important; */
  height: 50% !important;
`;

const ArticleContainer = styled.div`
  text-align: left;
  width: 280px !important;
  height: auto;
  ${breakpoint('sm')`
    width: 320px !important;
  `}
`;

const BackButton = styled(Link)`
  color: ${props => props.theme.color.text.light};
  font-size: 18px !important;
  text-decoration: underline;
  margin-top: 3%;
  margin-bottom: 7%;
`;

interface Props {
  data: any;
  displayFilters: boolean;
  theme: any;
  lang: string;
  backButtonText?: string;
  backButtonUrl?: string;
  renderItem?: (item: any, idx: number) => any;
  getItems?: (items: any[]) => any;
}

interface Filter {
  name: string;
  displayName: string;
}

interface State {
  selectedDate: string;
  filters: Filter[];
}

class ArticlesSections extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { data } = props;
    const articles = get(data, 'items', []);

    // NOTE: this will generate the filters (ie: ALL, 2019, 2018, 2017, etc..)
    // Retrieve all the articles creation date year
    let generatedFilters = articles.map(article => {
      const articleYear = get(
        article,
        'article.document[0].data.creation_date'
      );
      if (articleYear) {
        const year = moment(articleYear)
          .year()
          .toString();
        return {
          name: year,
          displayName: year,
        };
      }
      return null;
    });
    // Remove non valid filters
    generatedFilters = filter(generatedFilters, filter => filter !== null);
    // Make it unique
    generatedFilters = uniqBy(generatedFilters, 'name');
    // Order by most recent years first
    generatedFilters = orderBy(generatedFilters, ['name'], 'desc');
    // Add default selection
    generatedFilters = [
      {
        name: 'all',
        displayName: get(data, 'primary.filter_all_name', 'ALL'),
      },
      ...generatedFilters,
    ];
    this.state = {
      selectedDate: 'all',
      filters: generatedFilters,
    };
  }

  renderFilters = () => {
    const { theme } = this.props;

    return (
      <FiltersCol xs={24}>
        {this.state.filters.map((filter, index) => {
          return filter ? (
            <FilterContainer
              key={index}
              onClick={e => {
                this.setState({
                  selectedDate: filter.name,
                });
              }}
            >
              <FilterText
                color={
                  this.state.selectedDate === filter.name
                    ? theme.color.text.main
                    : theme.color.secondary.main
                }
              >
                {filter.displayName}
              </FilterText>
            </FilterContainer>
          ) : null;
        })}
      </FiltersCol>
    );
  };

  renderArticles = () => {
    const { data, renderItem, getItems } = this.props;

    let orderedByMostRecentArticles;

    if (typeof getItems === 'function') {
      orderedByMostRecentArticles = getItems(data);
    } else {
      const articles = get(data, 'items', []);
      orderedByMostRecentArticles = orderBy(
        articles,
        'article.document[0].data.creation_date',
        'desc'
      );
    }

    if (typeof renderItem === 'function') {
      return orderedByMostRecentArticles.map(renderItem);
    }

    return orderedByMostRecentArticles.map((article, idx) => {
      const imgData = get(
        article,
        'article.document[0].data.preview_image.localFile.childImageSharp.fluid'
      );
      const articleLink = getInternalUrl(
        get(article, 'article.document[0].data.url', ''),
        this.props.lang
      );

      const articleDate = get(
        article,
        'article.document[0].data.creation_date',
        ''
      );
      let articleYear;
      if (articleDate) {
        articleYear = moment(articleDate)
          .year()
          .toString();
      }

      const articlePdfLink = get(
        article,
        'article.document[0].data.article_pdf.url',
        null
      );
      const articleHtmlDescription = get(
        article,
        'article.document[0].data.content_extract.html',
        null
      );
      const articleDisplayDate = get(
        article,
        'article.document[0].data.creation_date',
        null
      );
      return this.state.selectedDate === 'all' ||
        articleYear === this.state.selectedDate ? (
        <ArticleCol xs={24} md={12} xl={8} key={idx}>
          <ArticleContainer>
            <Link
              to={articlePdfLink || articleLink}
              target={articlePdfLink ? '_blank' : ''}
            >
              <ArticlePreviewImage
                key={idx}
                fluid={{
                  aspectRatio: 1,
                  ...imgData,
                }}
                alt={get(
                  article,
                  'article.document[0].data.preview_image.alt',
                  'Nikanti image'
                )}
              />
            </Link>
            <ArticleTitle level={3}>
              {get(article, 'article.document[0].data.title', '')}
            </ArticleTitle>
            {articleDisplayDate ? (
              <DateText small>
                {moment(
                  get(article, 'article.document[0].data.creation_date', ''),
                  'YYYY-MM-DD'
                ).format('Do MMM YYYY')}
              </DateText>
            ) : null}
            <ArticleExtract small>
              {articleHtmlDescription ? (
                <span
                  dangerouslySetInnerHTML={{ __html: articleHtmlDescription }}
                />
              ) : (
                truncate(
                  get(article, 'article.document[0].data.content.text', ''),
                  { length: 180 }
                )
              )}
            </ArticleExtract>
            <Link
              to={articlePdfLink || articleLink}
              target={articlePdfLink ? '_blank' : ''}
            >
              <ReadMoreText small>
                {get(
                  article,
                  'article.document[0].data.read_more_text',
                  'Read more'
                )}
              </ReadMoreText>
            </Link>
          </ArticleContainer>
        </ArticleCol>
      ) : null;
    });
  };

  render() {
    const {
      data = null,
      displayFilters,
      backButtonText,
      backButtonUrl,
      lang = 'en-us',
    } = this.props;
    if (!data) return null;

    return (
      <Container>
        <FlowerPattern
          src={flowerGold1}
          height="100px"
          width="auto"
          right="96%"
          bottom="0"
          opacity={0.6}
        />
        <FlowerPattern
          src={flowerGold2}
          height="140px"
          width="auto"
          right="10px"
          bottom="80%"
          opacity={0.6}
        />
        {displayFilters &&
          this.state.filters &&
          this.state.filters.length > 1 && (
            <FiltersRow>{this.renderFilters()}</FiltersRow>
          )}
        <ArticlesRow type="flex" justify="left" align="top">
          {/* <Col xs={22} offset={1}>/ */}
          {this.renderArticles()}
          {/* </Col> */}
        </ArticlesRow>
        {backButtonText && backButtonUrl && (
          <ArticlesRow type="flex" justify="center" align="top">
            <BackButton to={getInternalUrl(backButtonUrl, lang)}>
              {backButtonText.toUpperCase()}
            </BackButton>
          </ArticlesRow>
        )}
      </Container>
    );
  }
}

export default withTheme(ArticlesSections);
