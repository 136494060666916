import styled, { css, ThemeProvider, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { StickyContainer, Sticky } from 'react-sticky';
import React, { Component, Fragment, useEffect, useState } from 'react';
import get from 'lodash/get';
import 'typeface-lato';
import Helmet from 'react-helmet';
import { window, exists } from 'browser-monads';

import { theme } from '../common/theme';

import Header from './Header';
import Footer from './Footer';
import Banner from './Banner';
import withPrismicHeader from './withPrismicHeader';

const localStorage = require('localStorage');

const Container = styled.div`
  background: rgb(24, 24, 24);
  background: linear-gradient(
    180deg,
    rgba(24, 24, 24, 1) 120vh,
    rgba(48, 48, 48, 1) 100%
  );
  min-height: 100vh;
  height: 100%;
  position: relative;
`;

const MobileBannerPadding = styled.div`
  padding-bottom: 65px;

  ${breakpoint('lg')`
   ${css`
     padding-bottom: 0px;
   `}
  `}
`;

const Main = styled.div``;

interface Props {
  data: any;
  children: React.ReactNode;
  isHomePage?: boolean;
  isHomepageLoadingComplete?: boolean;
  location: any;
  theme: any;
}

const HeaderSticky = ({
  lang,
  isHomePage,
  currentPage,
  hasBanner,
  ...props
}) => {
  return (
    <>
      <Sticky disableCompensation>
        {({ style, isSticky, wasSticky, distanceFromTop }) => {
          const screenHeight = exists(window) ? window.innerHeight : 0;
          const screenWidth = exists(window) ? window.innerWidth : 0;

          let isOnTop = !distanceFromTop || distanceFromTop >= 0;
          const displayDarkerLogo = false;

          if (isHomePage && distanceFromTop < 0) {
            isOnTop = distanceFromTop * -1 < screenHeight * 0.95;
          }

          let newStyle = { ...style };
          if (isSticky && hasBanner) {
            newStyle = { ...newStyle, top: screenWidth <= 991 ? 0 : 30 };
          }

          return (
            <div id="Header" style={{ ...newStyle, zIndex: 10 }}>
              <Header
                isOnTop={isOnTop}
                lang={lang}
                isHomePage={isHomePage}
                currentPage={currentPage}
                hasBanner={hasBanner}
                {...props}
              />
            </div>
          );
        }}
      </Sticky>
      {hasBanner && <MobileBannerPadding />}
    </>
  );
};
const HeaderStickyWithPrismic = withPrismicHeader(HeaderSticky);

class Layout extends Component<Props, any> {
  render() {
    const { data, children, isHomePage, location } = this.props;

    const lang = get(data, 'page.lang', 'en-us');
    const actualPage = get(data, 'page.data.url', '');
    // console.log('actualPage', actualPage);
    const enUrl = location.href
      ? location.href
          .replace('en/', 'en/')
          .replace('th/', 'en/')
          .replace('jp/', 'en/')
      : '/en';
    const thUrl = location.href
      ? location.href
          .replace('en/', 'th/')
          .replace('th/', 'th/')
          .replace('jp/', 'th/')
      : '/th';
    const jpUrl = location.href
      ? location.href
          .replace('en/', 'jp/')
          .replace('th/', 'jp/')
          .replace('jp/', 'jp/')
      : '/jp';
    const defaultUrl = location.href
      ? location.href.replace('en/', '').replace('th/', '').replace('jp/', '')
      : '';
    // console.log('DEFAULT URL', defaultUrl);
    // console.log('EN URL', enUrl);
    // console.log('TH URL', thUrl);
    // console.log('JP URL', jpUrl);
    // const allowTracking = typeof window !== `undefined` &&
    //   localStorage.getItem("pdpa_accept") === "true";
    // console.log("allowTracking" + allowTracking)
    // console.log("window" + window)
    // {allowTracking && <script async src={`https://www.googletagmanager.com/gtag/js?id=GTM-MZSS498`}></script>}
    // {allowTracking && (
    //   <script>
    //   {`
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag(){dataLayer.push(arguments);}
    //     gtag('js', new Date());
    //     gtag('config', "GTM-MZSS498");
    //   `}
    // </script>
    // )}
    return (
      <Container>
        <Helmet
          htmlAttributes={{ lang }}
          title={get(data, 'page.data.metadata.document[0].data.metaTitle', '')}
        >
          <meta
            name="description"
            content={get(
              data,
              'page.data.metadata.document[0].data.metaDescription',
              ''
            )}
          />
          <meta
            name="keywords"
            content={get(
              data,
              'page.data.metadata.document[0].data.metaKeywords',
              ''
            )}
          />
          <meta
            name="theme-color"
            content={get(
              data,
              'page.data.metadata.document[0].data.metaColor',
              ''
            )}
          />
          <meta
            name="viewport"
            content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, target-densitydpi=device-dpi"
          />
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon57.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon57.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon60.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon60.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon72.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon72.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon76.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon76.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon114.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon114.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon120.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon120.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon144.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon144.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon152.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon152.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon180.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon180.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon192.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon192.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon32.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon32.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon96.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon96.localFile.publicURL',
                ''
              )
            )}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={get(
              data,
              'page.data.metadata.document[0].data.icon16.localFile.childImageSharp.fluid.src',
              get(
                data,
                'page.data.metadata.document[0].data.icon16.localFile.publicURL',
                ''
              )
            )}
          />

          <link rel="alternate" href={defaultUrl} hrefLang="en" />
          <link rel="alternate" href={enUrl} hrefLang="en" />
          <link rel="alternate" href={thUrl} hrefLang="th" />
          <link rel="alternate" href={jpUrl} hrefLang="jp" />
        </Helmet>

        {/* <Main> */}
        <ThemeProvider theme={theme}>
          <Main>
            <StickyContainer>
              <Banner lang={lang} />
              <HeaderStickyWithPrismic
                lang={lang}
                isHomePage={isHomePage}
                currentPage={actualPage}
              />

              {children}
              {/* if screen size is smaller than 992 pixel and user is on the booking page, hide the footer */}
              {typeof window !== `undefined` &&
              window &&
              window.innerWidth <= 991 &&
              (window.location.pathname.indexOf('dev-booking') > 0 ||
                window.location.pathname.indexOf('booking') > 0) ? (
                <></>
              ) : (
                <Footer currentPage={actualPage} lang={lang} />
              )}
            </StickyContainer>
          </Main>
        </ThemeProvider>
      </Container>
    );
  }
}

export default Layout;
