import { Select } from 'antd';
import styled from 'styled-components';
//const { Option as OptionAntD } = Select;

export const Option = styled(Select.Option)`
  color: red !important;
  background: blue !important;
`;

const SelectWithStyled = styled(Select)`
  /* padding: ${props => (props.isMobile ? '5px 10px' : '10px 20px')}; */
  border: none;
  text-transform: uppercase;
  color: ${props =>
    props.isOnTop ? theme.color.text.main : props.theme.color.text.dark};
  
  .ant-select-selection{
    background:transparent;
    border:none;
  }
  .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    box-shadow:none;
  }
  li.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    color: red;
  }
`;

export default SelectWithStyled;
