import styled, { css, withTheme } from 'styled-components';
import { TimePicker } from 'antd';

const TimePickerWithStyled = styled(TimePicker)`
    // color: ${props => props.theme.color.primary.black} !important;
    width: 100% !important;

    &:hover {
        border-style: none;
        border-radius: 0px !important;
    }

    .ant-time-picker-icon .ant-time-picker-clock-icon, .ant-time-picker-clear .ant-time-picker-clock-icon {
        display: block;
        color: ${props => props.theme.color.text.main};
        line-height: 1;
    }

    .ant-time-picker:hover,
    .ant-time-picker:active,
    .ant-time-picker:focus,
    .ant-time-picker-input:hover,
    .aant-time-picker-input:focus
     {
        border-color: ${props => props.theme.color.background.main} !important;
        box-shadow: none !important;
        border-radius: 0px !important;
    }

    .ant-time-picker-input[disabled] {
        color: ${props => props.theme.color.text.gray};
        background-color: transparent;
        cursor: not-allowed;
        opacity: 1; 
    }

    .ant-time-picker-input[disabled]:hover {
        border-color: ${props => props.theme.color.background.black} !important;
    }

`;

export default withTheme(TimePickerWithStyled);