import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import breakpoint from 'styled-components-breakpoint';
import { Row, Col } from 'antd';
import Text from './Text';

const Card = styled(Row)`
  flex-direction: column !important;
  background-color: ${props => props.color || '#f4f0e5'};
  height: 100%;

  ${breakpoint('sm')`
    flex-direction: row !important;
  `}
`;

const CoverImageContainer = styled(Col)`
  height: 100%;
  overflow: hidden;
`;

const CoverImage = styled(Img)`
  position: relative;
  width: 100%;
  height: 250px;
  ${breakpoint('md')`
     height: 100%;
  `}
  /* transition: transform 0.5s ease; */

  ${props =>
    props.showAnimation &&
    css`
      :hover {
        /* transform: scale(1.3); */
      }
    `}
`;

const ContentContainer = styled(Col)``;

const Content = styled(Row)`
  flex-direction: column !important;
  height: 100%;
`;

const Title = styled(Text)`
  font-size: 18px;
  text-transform: uppercase;
`;

const LinkText = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`;

Card.CoverImageContainer = CoverImageContainer;
Card.CoverImage = CoverImage;
Card.ContentContainer = ContentContainer;
Card.Content = Content;
Card.Title = Title;
Card.LinkText = LinkText;

export default Card;
