import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Icon } from 'antd';
import get from 'lodash/get';
import TweenOne from 'rc-tween-one';
import { Slider } from '.';

const SlideImageContainer = styled.div`
  width: 100%;
  height: ${props => (props.isHomePage ? '50vh' : '100%')};
  .slick-slider,
  .slick-list,
  .slick-track,
  img,
  div {
    height: 100%;
  }
  ul {
    list-style: none;
    display: none;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0;
  }
  slick-dots {
    display: none;
  }
  ${breakpoint('lg')`
    ${props => props.isHomePage && 'display:none;'}
  `}
`;

const CarouselArrowIcon = styled(Icon)`
  width: auto !important;
  height: auto !important;

  ${props =>
    props.force
      ? css`
          display: block !important;
        `
      : css`
          display: none !important;
          ${breakpoint('lg')`
            display:block!important;
          `}
        `}

  z-index:5;
  color: ${props => props.theme.color.text.main}!important;

  &:hover {
    color: ${props => props.theme.color.primary.main}!important;
  }

  font-size: ${props => (props.size === 'normal' ? '8vw' : '6vw')} !important;
  ${breakpoint('sm')`
    font-size: ${props =>
      props.size === 'normal' ? '45px' : '22.5px'} !important;
  `}

  ${props =>
    props.type &&
    (props.type === 'right' || props.type === 'left') &&
    css`
      ${props.type}: ${props => (props.isHero ? 15 : 10)}px !important;
    `}
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
`;

const TweenOneWithStyled = styled(TweenOne)`
  opacity: 0;
  position: absolute;
  top: 0;
  ${props =>
    props.type &&
    (props.type === 'right' || props.type === 'left') &&
    css`
      ${props.type}: 0px !important;
    `}
`;
const CarouselArrow = ({ type, size, force, ...props }) => (
  <TweenOneWithStyled
    type={type}
    animation={{
      opacity: 1,
      delay: 500,
    }}
  >
    <CarouselArrowIcon
      type={type}
      size={size}
      force={force}
      onClick={props.onClick}
      className={props.className}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    />
  </TweenOneWithStyled>
);

interface CarouselProps {
  items: any[];
  arrowSize?: 'small' | 'normal';
  forceDisplayArrows?: boolean;
  isHero?: boolean;
  isHomepage?: boolean;
  swipeDirection?: boolean;
  renderItem?: (item: any, idx: number) => any;
  autoplay: boolean;
}

class Carousel extends React.Component<CarouselProps, any> {
  slider;

  componentDidUpdate(prevProps) {
    if (prevProps.autoplay !== this.props.autoplay && this.slider) {
      if (this.props.autoplay) {
        this.slider.innerSlider.play();
        this.slider.slickPlay();
      } else {
        this.slider.slickPause();
      }
    }
  }

  onMouseEnterArrows = () => {
    if (this.slider) {
      this.slider.slickPause();
    }
  };

  onMouseLeaveArrows = () => {
    if (this.slider && this.props.autoplay) {
      this.slider.slickPlay();
    }
  };

  render() {
    const {
      items = [],
      arrowSize = 'normal',
      forceDisplayArrows = false,
      renderItem,
      isHero,
      swipeDirection = false,
      isHomepage,
    } = this.props;

    const settings = {
      rtl: swipeDirection,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: !!isHero || !!isHomepage,
      speed: 1000,
      autoplaySpeed: 3000,
      cssEase: 'linear',
      pauseOnHover: !!isHero,
      nextArrow: (
        <CarouselArrow
          size={arrowSize}
          force={forceDisplayArrows}
          type="right"
          isHero={isHero}
          onMouseEnter={this.onMouseEnterArrows}
          onMouseLeave={this.onMouseLeaveArrows}
        />
      ),
      prevArrow: (
        <CarouselArrow
          size={arrowSize}
          force={forceDisplayArrows}
          type="left"
          isHero={isHero}
          onMouseEnter={this.onMouseEnterArrows}
          onMouseLeave={this.onMouseLeaveArrows}
        />
      ),
    };
    return (
      <Slider settings={settings} ref={slider => (this.slider = slider)}>
        {typeof renderItem === 'function'
          ? items.map(renderItem)
          : items.map((item, idx) => {
              const url = get(item, 'image.url', '');
              return (
                <SlideImage
                  key={`slideImage_${idx}`}
                  src={url}
                  isHero={isHero}
                />
              );
            })}
      </Slider>
    );
  }
}

interface Props {
  images: any;
  isHomePage?: boolean;
  arrowSize?: 'small' | 'normal';
  forceDisplayArrows?: boolean;
  isHero?: boolean;
  swipeDirection?: boolean;
  renderItem?: (item: any, idx: number) => any;
}
class ImageSlider extends Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: !!props.isHero || !!props.isHomePage,
    };
  }

  render() {
    return (
      <SlideImageContainer
        isHomePage={this.props.isHomePage}
        onMouseEnter={() => this.setState({ autoplay: true })}
        onMouseLeave={() => this.setState({ autoplay: !!this.props.isHero })}
      >
        <Carousel
          isHomepage={this.props.isHomePage}
          items={this.props.images}
          renderItem={this.props.renderItem}
          arrowSize={this.props.arrowSize}
          forceDisplayArrows={this.props.forceDisplayArrows}
          isHero={this.props.isHero}
          swipeDirection={this.props.swipeDirection}
          autoplay={this.state.autoplay}
        />
      </SlideImageContainer>
    );
  }
}

export default ImageSlider;
