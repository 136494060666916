import React from 'react';
import styled, { withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import get from 'lodash/get';
import { Row, Col, Form, Upload, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Title, Text, Input, Paragraph } from '../common/components';
import { EMAIL_REGEX, PHONE_REGEX } from '../constants/pattern';
import { sendMail } from '../utils/sendMail';

interface FormProps extends FormComponentProps {
  name: string;
  email: string;
  phone: string;
  message: string;
  file?: File;
}

type Props = FormProps & {
  data: any;
  theme: any;
  form: Form;
};

type State = {
  fileList: any[];
  loading: boolean;
  name: string;
  email: string;
  phone: string;
  message: string;
  feedbackMessage: string;
  file?: File;
};

const FormContainer = styled(Row)`
  background-color: ${props => props.theme.color.background.black};
  padding: 30px 26px 30px;

  ${breakpoint('sm')`
    padding: 40px 36px 40px;
  `}
`;

const FormTitle = styled(Title)`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 28px !important;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 15px !important;
`;

const UploadContainer = styled(Upload)`
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: transparent;
  }
`;

const UploadButton = styled(Text)`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.color.primary.main} !important;
  }
`;

const SubmitButtonContainer = styled(Form.Item)`
  margin-top: 15px !important;
  margin-bottom: 10px !important;

  & .ant-form-item-control {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SubmitButtonText = styled(Title)`
  text-decoration: underline;
  text-transform: uppercase;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${props => props.theme.color.primary.main} !important;
  }
`;

const SubmitButton = styled(Button)`
  background: transparent;
  border: none;
  box-shadow: none;

  &:hover,
  &:focus {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  &.ant-btn-loading {
    &::before {
      background: transparent;
    }

    & > .anticon {
      padding-right: 10px;
    }

    & > ${SubmitButtonText} {
      display: inline-block;
      color: ${props => props.theme.color.secondary.light} !important;
    }
  }

  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }
`;

const FeedbackMessage = styled(Paragraph)`
  margin-top: 30px;
`;

class CareersForm extends React.Component<Props, State> {
  state = {
    file: undefined,
    fileList: [],
    loading: false,
    feedbackMessage: '',

    // inputs
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  resetFields = () => {
    this.setState({
      file: undefined,
      fileList: [],
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  clearFeedbackMessage = () => {
    this.setState({ feedbackMessage: '' });
  };

  buildEmailHtml = values => `<html>
<head>
</head>
<body>
<table id="box" cellpadding="0" cellspacing="0" border="0" align="center" style="width: 100%; border-collapse: collapse; margin: 50px auto; font-family: 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif; font-size: 15px;" bgcolor="white" width="100%">
  <tbody>
    <tr>
      <td>
        <table id="summary-box" style="border-spacing: 20px 0;">
          <tr>
            <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Name</p><p style="font-weight: bold; font-size: 14px;"></p></td>
            <td class="property-value"><p style="font-size: 13px;">${get(
              values,
              'name',
              'n/a'
            )}</p></td>
          </tr>
          <tr>
            <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Email</p><p style="font-weight: bold; font-size: 14px;"></p></td>
            <td class="property-value"><p style="font-size: 13px;">${get(
              values,
              'email',
              'n/a'
            )}</p></td>
          </tr>
          <tr>
            <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Phone number</p><p style="font-weight: bold; font-size: 14px;"></p></td>
            <td class="property-value"><p style="font-size: 13px;">${get(
              values,
              'phone',
              'n/a'
            )}</p></td>
          </tr>
          <tr>
            <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Message</p><p style="font-weight: bold; font-size: 14px;"></p></td>
            <td class="property-value"><p style="font-size: 13px;">${get(
              values,
              'message',
              'n/a'
            )}</p></td>
          </tr>
        </table>
      </td>
    </tr>
  </tbody>
</table>
</body>
</html>`;

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true, feedbackMessage: '' });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const destinations = get(this.props.data, 'destinations');
          const cc = get(this.props.data, 'cc');
          const subject =
            get(this.props.data, 'subject') +
            ' - ' +
            get(values, 'email', 'n/a');

          await sendMail(
            destinations,
            subject,
            this.buildEmailHtml(values),
            `${get(values, 'name')} send a job application...`,
            this.state.fileList,
            cc
          );

          this.resetFields();

          const successMessage = get(this.props.data, 'successMessage.html');
          if (successMessage) {
            this.setState({ feedbackMessage: successMessage });
          }
        } catch (e) {
          const errorMessage = get(this.props.data, 'errorMessage.html');
          if (errorMessage) {
            this.setState({ feedbackMessage: errorMessage });
          }
        }
      }

      this.setState({ loading: false });
    });
  };

  handleUpload = info => {
    const { file, fileList } = info;

    this.setState({
      file,
      fileList: fileList.length === 0 ? [] : [file],
    });
  };

  render() {
    const { data, theme, form } = this.props;
    const { fileList, loading, feedbackMessage } = this.state;

    const title = get(data, 'title');
    const name = get(data, 'name');
    const email = get(data, 'email');
    const phone = get(data, 'phone');
    const message = get(data, 'message');
    const attachmentButtonText = get(data, 'attachmentButtonText', '');
    const submitButtonText = get(data, 'submit', 'Submit');

    return (
      <FormContainer type="flex" align="middle">
        <Col span={24}>
          {title && (
            <FormTitle level={3} color={theme.color.primary.light}>
              {title}
            </FormTitle>
          )}
          <Form onSubmit={this.handleSubmit}>
            <FormItem>
              {form.getFieldDecorator('name', {
                rules: [
                  { required: true, message: 'Please input your name!' },
                  { min: 3, message: 'Your name is too short' },
                ],
              })(
                <Input.Container>
                  <Input
                    placeholder={name}
                    disabled={loading}
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </Input.Container>
              )}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Please input your email!' },
                  { pattern: EMAIL_REGEX, message: 'Your email is invalid!' },
                ],
              })(
                <Input.Container>
                  <Input
                    placeholder={email}
                    disabled={loading}
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </Input.Container>
              )}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator('phone', {
                rules: [
                  { required: true, message: 'Please input your phone!' },
                  {
                    pattern: PHONE_REGEX,
                    message: 'Your phone number is invalid!',
                  },
                ],
              })(
                <Input.Container>
                  <Input
                    placeholder={phone}
                    disabled={loading}
                    value={this.state.phone}
                    onChange={e => this.setState({ phone: e.target.value })}
                  />
                </Input.Container>
              )}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator('message', {
                rules: [
                  { required: true, message: 'Please input your message!' },
                ],
              })(
                <Input.TextAreaContainer>
                  <Input.TextArea
                    autosize={{ minRows: 8, maxRows: 8 }}
                    placeholder={message}
                    style={{ resize: 'none' }}
                    disabled={loading}
                    value={this.state.message}
                    onChange={e => this.setState({ message: e.target.value })}
                  />
                </Input.TextAreaContainer>
              )}
            </FormItem>
            {attachmentButtonText && (
              <UploadContainer
                name="file"
                onChange={this.handleUpload}
                accept=".pdf,.jpg,.png"
                beforeUpload={() => false}
                fileList={fileList}
                disabled={loading}
              >
                <UploadButton small>{attachmentButtonText}</UploadButton>
              </UploadContainer>
            )}
            <SubmitButtonContainer>
              <SubmitButton htmlType="submit" loading={loading}>
                <SubmitButtonText level={4}>
                  {submitButtonText}
                </SubmitButtonText>
              </SubmitButton>
            </SubmitButtonContainer>
          </Form>
          {feedbackMessage && (
            <FeedbackMessage small>
              <span dangerouslySetInnerHTML={{ __html: feedbackMessage }} />
            </FeedbackMessage>
          )}
        </Col>
      </FormContainer>
    );
  }
}

const WrappedCareersForm = Form.create<Props>({ name: 'careers-form' })(
  CareersForm
);

export default withTheme(WrappedCareersForm);
