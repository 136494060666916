import styled from 'styled-components';

const Spacer = styled.div`
  width: 50px;
  height: 3px;
  background-color: ${props => props.color || props.theme.color.primary.main};
  border: none;
  margin: ${props => (props.margin ? props.margin : '1em auto')};
  text-align: center;
`;

export default Spacer;
