import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { Title, Spacer, FlowerPattern } from '.';

import flower1 from '../../assets/flower-3-black.svg';
import flower2 from '../../assets/flower-2-black.svg';

const SectionTitleContainer = styled.div`
  position: relative;
  margin: 30px 40px 0px 40px;
  max-height: 100%;
  overflow: hidden !important;
`;

const TitleText = styled(Title)`
  text-align: center;
  font-weight: 400 !important;
`;

interface Props {
  title: string;
  flowerRight?: any;
  flowerLeft?: any;
  theme: any;
}

class SectionTitle extends Component<Props, any> {
  render() {
    const { title, theme, flowerRight, flowerLeft } = this.props;
    return (
      <SectionTitleContainer>
        {/* <FlowerPattern
          src={flowerLeft || flower1}
          height={'200px'}
          width={'auto'}
          right={'95%'}
          bottom={'-120px'}
          opacity={0.6}
        />
        <FlowerPattern
          src={flowerRight || flower2}
          height={'200px'}
          width={'auto'}
          right={'-50px'}
          bottom={'-100px'}
          opacity={0.6}
        /> */}
        <TitleText color={theme.color.primary.main} level={1}>
          {title}
        </TitleText>
        <Spacer color={theme.color.secondary.main} margin="2em auto" />
      </SectionTitleContainer>
    );
  }
}

export default withTheme(SectionTitle);
