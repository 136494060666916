// Note: these are the expected min/max font sizes
// in an human readable form, if you want to
// change ranges you have to also modify the calc formula from getSize() func
// See: https://websemantics.uk/tools/responsive-font-calculator/
// Settings use: pixels units with 480px to 1200px

const H1_SIZES = { min: 24, max: 30 };
const H2_SIZES = { min: 19, max: 24 };
const H3_SIZES = { min: 15, max: 20 };
const H4_SIZES = { min: 12, max: 16 };

export const getSize = (title: number) => {
  switch (title) {
    case 1:
      return 'calc(1.5rem + ((1vw - 4.8px) * 0.8333))'; // 24 => 30px
    case 2:
      return 'calc(1.1875rem + ((1vw - 4.8px) * 0.6944))'; // 19 => 24px
    case 3:
      return 'calc(0.9375rem + ((1vw - 4.8px) * 0.6944))'; // 15 => 20px
    case 4:
      return 'calc(0.75rem + ((1vw - 4.8px) * 0.4167))'; // 12 => 15px
    default:
      return 'calc(1.5rem + ((1vw - 4.8px) * 0.8333))'; // Default 24 => 30px
  }
};

export const getMaxSize = (title: number) => {
  switch (title) {
    case 1:
      return H1_SIZES.max;
    case 2:
      return H2_SIZES.max;
    case 3:
      return H3_SIZES.max;
    case 4:
      return H4_SIZES.max;
    default:
      return H1_SIZES.max;
  }
};
