import { Input } from 'antd';
import styled, { css } from 'styled-components';

const commonInput = css`
  /* padding: ${props => (props.isMobile ? '5px 10px' : '10px 20px')}; */
  border: 1px solid transparent !important;

  &:hover{
    border: 1px solid transparent !important;
    box-shadow: none !important;
  }

  &:focus{
    border: 1px solid transparent !important;
    box-shadow: none !important;
  }

  * {
    :hover{
      border: 1px solid transparent !important;
    }
    :focus{
      border: 1px solid transparent !important;
      box-shadow: none !important;
    }
  }

  ${props => props.placeholderColor
    && css`
      &::placeholder,
      *::-webkit-input-placeholder {
        color: ${props => props.placeholderColor} !important;
      }
      &:-ms-input-placeholder {
        color: ${props => props.placeholderColor} !important;
      }
    `}

  .has-error & .ant-input {
    border: 1px solid transparent !important;
  }

  &.ant-input-disabled, & .ant-input-disabled {
    background: transparent !important;
    color: ${props => props.theme.color.text.gray} !important;
  }
`;

const InputWithStyled = styled(Input)`
  ${commonInput}
`;

const InputTextAreaWithStyled = styled(Input.TextArea)`
  ${commonInput}
`;

InputWithStyled.TextArea = InputTextAreaWithStyled;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.backgroundColor || props.theme.color.background.main};
  height: 50px;
  overflow: hidden;
  width: 100%;
  padding: 5px;
  justify-content: space-between;

  * {
    font-family: ${props => props.theme.fontFamily};
  }

  .has-error & {
    border: 1px solid #f5222d !important;
  }
`;

InputWithStyled.Container = InputContainer;

const InputTextAreaContainer = styled.div`
  background-color: ${props => props.theme.color.background.main};
  padding: 5px;

  * {
    font-family: ${props => props.theme.fontFamily};
  }

  .has-error & {
    border: 1px solid #f5222d !important;
  }
`;

InputWithStyled.TextAreaContainer = InputTextAreaContainer;

export default InputWithStyled;
