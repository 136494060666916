import React, { Component } from 'react';
import get from 'lodash/get';

import styled, { withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Image, ImageSlider } from '.';
import { ITheme } from '../theme';

interface Props {
  data: any;
  theme: ITheme;
}

const Container = styled.div`
  /* min-height: calc(100vh - (105px)); */
  height: 40vh;
  position: relative;
  flex-direction: column;

  ${breakpoint('lg')`
    height: 80vh;
  `}
`;
const Gradient = styled.div`
  /* min-height: calc(100vh - (105px)); */
  background: transparent;
  background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, rgba(24, 24, 24, 1) 100%);
  position: absolute;
  height: 25%;
  width: 100%;
  bottom: -2px;
  pointer-events: none;
`;

// const inputRef = useRef();
class PageHero extends Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderImage = ({ image }, idx) => <Image data={image} index={idx} />;

  render() {
    const { data, theme } = this.props;
    const images = get(data, 'items', []);

    return (
      <Container>
        <ImageSlider images={images} isHomePage={false} isHero renderItem={this.renderImage} />
        <Gradient />
      </Container>
    );
  }
}

export default withTheme(PageHero);
