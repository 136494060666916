import { Avatar, Col, Dropdown, Icon, Menu, Row } from 'antd';
import { exists, window } from 'browser-monads';
import { graphql, navigate, StaticQuery } from 'gatsby';
import { keys } from 'lodash';
import get from 'lodash/get';
import compact from 'lodash/compact';
import TweenOne from 'rc-tween-one';
import React from 'react';
import Media from 'react-media';
import styled, { css, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Button, Image, Link, Select, Title } from '../common/components';
import { ITheme } from '../common/theme';
import { LANGUAGE } from '../constants/language';
import { getInternalUrl } from '../utils';

import withPrismicHeader from './withPrismicHeader';

const { Option } = Select;
const ANIMATION_DURATION = 1000;

const TweenOneWithStyled = styled(TweenOne)`
  ${(props) => (props.isHomePage ? 'height: 105px;' : 'height: 60px;')}
  /* position: absolute;
  width: 100%; */
  ${(props) =>
    props.isOnTop
      ? 'background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));'
      : 'background-image: linear-gradient(rgba(0, 0, 0, 1),  rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));'}
`;

const OptionStyled = styled.div`
  * {
    color: ${(props) => props.theme.color.secondary.main}!important;
  }
`;

const Container = styled.div`
  z-index: 3;
  ${(props) =>
    props.isHomePage ? 'min-height: 105px;' : '  min-height: 60px;'}
  background: transparent;
  ${(props) => props.isOnTop && 'box-shadow: none;'}
  /* position: ${(props) => (props.menuOpen ? 'fixed' : 'absolute')}; */
  width: 100%;
  ${(props) => !props.menuOpen && 'position: absolute;'}/*${(props) =>
    props.isOnTop && 'width: 100%;'} */
  /* position: absolute;
  width: 100%; */
`;

const ButtonWithStyled = styled(Button)`
  text-transform: uppercase;
  min-width: 60px;
  height: 60px;
  font-size: 26px;
`;

const MobileMenuButton = styled(ButtonWithStyled)`
  padding: 0px 10px;
`;

const StyledGroupMenuItem = styled(Menu.ItemGroup)`
  .ant-dropdown-menu-item-group-title {
    color: #6f5f44;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0px;
  }

  .ant-dropdown-menu-item {
    padding-left: 32px;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  color: #3e3e3f;
`;

// const BookButtonWithStyled = styled(Button)`
//   text-transform: uppercase;
//   width: auto;
//   height: 60px;
//   ${breakpoint('lg')`
//     height: 52px;
//   `}
// `;

const SelectLanguage = styled(Select)`
  width: 65px;
`;

const StyledToolbar = styled.div`
  background: transparent;
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-height: inherit;
  max-height: 120px;
  position: relative;
`;

const LinkMenu = styled(Link)`
  * {
    color: ${(props) => {
      return props.isCurrentPage
        ? props.theme.color.primary.main
        : props.isOnTop
        ? props.theme.color.text.main
        : props.theme.color.text.dark;
    }} !important;
  }
  margin: 0.5rem 1rem;
  ${breakpoint('xl')`
    margin: 0.5rem 1rem;
  `}
  letter-spacing: 0.1rem;
  ${breakpoint('lg')`
    font-size:14px;
    font-weight: bold;
    letter-spacing: 0rem;
  `}
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${(props) => props.theme.color.primary.main};
  }
`;

// const BookLink = styled(Link)`
//   color: black;
//   font-size: 16px;
//   font-family: ${props => props.theme.fontFamily};
//   &:hover,
//   &:focus {
//     text-decoration: none;
//     color: ${props => props.theme.color.primary.main};
//   }
// `;

const LogoImage = styled(Image)`
  ${(props) =>
    props.small &&
    css`
      margin-top: 10px;
    `}
  height:${(props) => (props.small ? 40 : 80)}px;
  ${breakpoint('xs')`
    height:${(props) => (props.small ? 40 : 90)}px;
  `}
  ${breakpoint('md')`
    height:${(props) => (props.small ? 40 : 110)}px;
  `}
  ${breakpoint('lg')`
    height:${(props) => (props.small ? 40 : 80)}px;
  `}
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 2rem;
`;

const TweenOneRightContainer = styled(TweenOne)`
  position: absolute;
  right: 20px;
  top: 2rem;
  /*top: calc(2rem + 36px);*/
`;

const RightContainer = styled.div`
  display: flex;
`;

const LanguageContainer = styled.div`
  * {
    color: ${(props) =>
      props.isOnTop ? theme.color.text.main : props.theme.color.text.dark};
    font-weight: bold;
    font-family: ${(props) => props.theme.fontFamily};
  }
  p {
    margin: 0;
    margin-left: 0.5rem;
  }
  display: flex;
  ${breakpoint('md')`
  margin-right: 1rem;`}
  align-items: center;
`;

const LogoContainer = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  margin: 20px;
  ${breakpoint('lg')`
     margin: 0 10px;
  `}
`;

const MobileToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6)
  );
  /* height:100vh; */
  ${(props) => props.menuOpen && 'height: 100vh;'}
`;

const MenuMobileInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuMobileSignUpInner = styled(MenuMobileInner)`
  margin-bottom: 100px;
`;

const MenuMobileLanguageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  margin-left: 1rem;
`;

const MenuMobileContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: ${(props) => (props.open ? '100vh' : '0')};
  width: 100vw;

  flex-direction: column;
  overflow-y: scroll;

  background: linear-gradient(
    180deg,
    rgba(24, 24, 24, 1) 0%,
    rgba(48, 48, 48, 1) 100%
  );
  color: '#fafafa';
  transition: 'height 0.3s ease';
  z-index: 5;
`;

const GatsbyLinkItem = styled(Link)`
  color: white;
  padding: 0 1rem;
  border-right: 2px solid;
  font-family: ${(props) => props.theme.fontFamily};
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${(props) => props.theme.color.primary.main};
    border-color: white;
  }
`;

const SmallLogoContainer = styled.div`
  display: ${(props) => (props.show ? 'display' : 'none')};
  width: 100%;
  height: 60px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const SmallLogoImage = styled.img`
  width: 100%;
  height: 100%;
`;

const BookingButton = styled.button`
  min-height: initial;
  background-color: #b59660 !important;
  border-style: none;
  border-color: #b59660 !important;
  color: #ffffff !important;
  height: 32px;
  width: 95px;
  font-size: 14px;
  font-weight: 400 !important;
  cursor: pointer;
  outline: none !important;
`;

const MobileBookingButton = styled(Button)`
  margin: 1rem;
  width: 150px;
  min-height: initial;
  color: #ffffff !important;
  background-color: #b59660 !important;
`;

const ProfileAvatar = styled(Avatar)`
  color: #1e1e1e;
  background-color: ${(props) => props.theme.color.primary.main};
  font-size: 20px !important;
`;

const LogoutAvatar = styled(Avatar)`
  color: #ffffff;
  background-color: transparent;
  font-size: 25px !important;
  border: 2px solid;
`;

const TextRow = styled(Row)`
  flex-flow: row nowrap;
`;

const TitleRow = styled(TextRow)`
  margin: 10px 0px 30px 1rem;
  display: flex;
  align-items: center;
`;

const ContentColHeader = styled(Col)`
  padding-left: 10px;
`;

const LabelTitle = styled(Title)`
  margin: 0px !important;
`;

const MobileProfileAvatar = styled(Avatar)`
  color: #1e1e1e;
  background-color: ${(props) => props.theme.color.primary.main};
  font-size: 32px !important;
`;

const MenuDivider = styled.hr`
  margin: 1rem 0.8rem;
  width: 45%;
`;

const StyledIcon = styled(Icon)`
  font-size: 20px;
`;

const StyledMenuIcon = styled(Icon)`
  font-size: 30px;
`;

const MenuButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const IconContainer = styled.div`
  padding-top: 5px;
  margin-right: 5px;
`;

const CircleContainer = styled.div`
  border-radius: 50%;
  border: 2px solid;
`;

const UserProfileTitle = (props) => {
  return (
    <TitleRow align="middle">
      <Col xs={4}>
        <MobileProfileAvatar size={50} gap={0}>
          {props.firstName.substring(0, 1).toUpperCase()}
        </MobileProfileAvatar>
      </Col>
      <ContentColHeader xs={20}>
        <TextRow align="middle">
          <Col xs={24}>
            <LabelTitle level={3} id={`${props.idPrefix}_text_welcomeMobile`}>
              {props.welcomeMessage +
                ', ' +
                props.firstName +
                ' ' +
                props.lastName}
            </LabelTitle>
          </Col>
        </TextRow>
        <TextRow>
          <Col xs={24}>
            <LabelTitle level={4} id={`${props.idPrefix}_text_userEmail`}>
              {props.email}
            </LabelTitle>
          </Col>
        </TextRow>
      </ContentColHeader>
    </TitleRow>
  );
};

const MenuMobile = ({
  open,
  userName,
  userEmail,
  isLoggedIn,
  isAdmin,
  isAgent,
  children,
  close,
  onMenuClose,
  onLogout,
  logo,
  lang,
  isOnTop,
  currentPage,
  handleUserMenu,
  showBooking,
  prismicData,
}) => {
  // Add Homepage Link only in mobile menu bar
  children.unshift(
    <LinkMenu
      key={'menu_homepage_0'}
      isOnTop={isOnTop}
      to={getInternalUrl('/', lang)}
      isCurrentPage={'/' === currentPage}
    >
      <Title level={4}>HOMEPAGE</Title>
    </LinkMenu>
  );

  // array of first name and last name of user
  const userNameArray = userName ? userName.split(' ') : ['', ''];

  const urlParams = new URLSearchParams(window.location.search);

  return (
    <MenuMobileContainer open={open}>
      <div>
        <ButtonWithStyled isMobile onClick={close}>
          <Icon type="close" />
        </ButtonWithStyled>
      </div>

      {/* Logo */}
      <LinkMenu to={getInternalUrl('/', lang)}>
        <LogoContainer show={true}>
          {logo && <LogoImage isMobile alt={get(logo, 'alt')} data={logo} />}
        </LogoContainer>
      </LinkMenu>

      {!isLoggedIn || !showBooking || (
        <>
          <MenuMobileInner>
            <UserProfileTitle
              firstName={userNameArray[0]}
              lastName={userNameArray[1]}
              email={userEmail}
              welcomeMessage={get(
                prismicData,
                'node.data.welcome_menu_text',
                'Welcome1'
              )}
              idPrefix="userProfile"
            ></UserProfileTitle>

            {
              // show manage coupons page if user is an admin
              !isAdmin || (
                <LinkMenu
                  key={'coupons_00'}
                  isOnTop={isOnTop}
                  onClick={(e) =>
                    handleUserMenu({ key: 'manage-coupons', domEvent: e })
                  }
                >
                  <Title level={4}>
                    {get(
                      prismicData,
                      'node.data.manage_coupons_menu_link',
                      'MANAGE COUPONS'
                    )}
                  </Title>
                </LinkMenu>
              )
            }

            {
              // show manage tee times page if user is an admin
              !isAdmin || (
                <LinkMenu
                  key={'tee_times_00'}
                  isOnTop={isOnTop}
                  onClick={(e) =>
                    handleUserMenu({ key: 'manage-tee-times', domEvent: e })
                  }
                >
                  <Title level={4}>
                    {get(
                      prismicData,
                      'node.data.manage_tee_times_menu_link',
                      'MANAGE TEE TIMES'
                    )}
                  </Title>
                </LinkMenu>
              )
            }

            {
              // show manage users bookings page if user is an admin
              !isAdmin || (
                <LinkMenu
                  key={'user_bookings_00'}
                  isOnTop={isOnTop}
                  onClick={(e) =>
                    handleUserMenu({ key: 'admin-manage-bookings', domEvent: e })
                  }
                >
                  <Title level={4}>
                    {get(
                      prismicData,
                      'node.data.manage_user_bookings_menu_link',
                      'MANAGE USER BOOKINGS'
                    )}
                  </Title>
                </LinkMenu>
              )
            }

{
              // show manage users bookings page if user is an admin
              !isAdmin || (
                <LinkMenu
                  key={'user_bookings_00'}
                  isOnTop={isOnTop}
                  onClick={(e) =>
                    handleUserMenu({ key: 'admin-manage-customers', domEvent: e })
                  }
                >
                  <Title level={4}>
                    {get(
                      prismicData,
                      'node.data.customer.list',
                      'MANAGE USER ACCOUNT'
                    )}
                  </Title>
                </LinkMenu>
              )
            }

            <LinkMenu
              key={'menu_account_01'}
              isOnTop={isOnTop}
              // to={getInternalUrl(link1, tmpLang)}
              // isCurrentPage={link1 === currentPage}
              onClick={(e) =>
                handleUserMenu({ key: isAgent ? 'agency-details' : 'user-profile', domEvent: e })
              }
              //state={{ prevPath: location.pathname }}
            >
              <Title level={4}>
                {get(prismicData, 'node.data.account_menu_link', 'ACCOUNT')}
              </Title>
            </LinkMenu>

            <LinkMenu
              key={'menu_manage-booking_02'}
              isOnTop={isOnTop}
              // to={getInternalUrl(link2, tmpLang)}
              // isCurrentPage={link2 === currentPage}
              onClick={(e) =>
                handleUserMenu({ key: isAgent ? 'agency-manage-bookings' : 'booking-history', domEvent: e })
              }
              //state={{ prevPath: location.pathname }}
            >
              <Title level={4}>
                {get(
                  prismicData,
                  'node.data.manage_bookings_menu_link',
                  'MANAGE BOOKINGS'
                )}
              </Title>
            </LinkMenu>

            <LinkMenu
              key={'menu_logout_03'}
              isOnTop={isOnTop}
              onClick={(e) => handleUserMenu({ key: 'logout', domEvent: e })}
              // to={getInternalUrl(link3, tmpLang)}
              // isCurrentPage={link3 === currentPage}
              //state={{ prevPath: location.pathname }}
            >
              <Title level={4}>
                {get(prismicData, 'node.data.logout_menu_link', 'LOG OUT')}
              </Title>
            </LinkMenu>

            <MenuDivider />
          </MenuMobileInner>
        </>
      )}

      {!showBooking || (
        <MenuMobileInner>
          <MobileBookingButton
            type="primary"
            size="large"
            id="header_btn_bookNow"
            onClick={(e) => handleUserMenu({ key: 'search', e })}
          >
            {get(prismicData, 'node.data.book_now_menu_button', 'Book Now')}
          </MobileBookingButton>
        </MenuMobileInner>
      )}

      <MenuMobileInner>{children}</MenuMobileInner>
      <MenuMobileLanguageContainer>
        {keys(LANGUAGE).map((key, idx) => (
          <GatsbyLinkItem
            // isFirst={idx === 0}
            // isLast={idx === keys(LANGUAGE).length - 1}
            key={'menu_language_' + key + idx}
            to={`/${LANGUAGE[key]}/${currentPage}?${urlParams}`}
          >
            {LANGUAGE[key].toUpperCase()}
          </GatsbyLinkItem>
        ))}
      </MenuMobileLanguageContainer>

      {isLoggedIn || !showBooking || (
        <MenuMobileSignUpInner>
          <LinkMenu
            key={'menu_sign-up_01'}
            isOnTop={isOnTop}
            // to={getInternalUrl(link4, tmpLang)}
            // isCurrentPage={link4 === currentPage}
            onClick={(e) => handleUserMenu({ key: 'register', domEvent: e })}
            //state={{ prevPath: location.pathname }}
          >
            <Title level={4}>
              {get(prismicData, 'node.data.sign_up_menu_link', 'SIGN UP')}
            </Title>
          </LinkMenu>
          <LinkMenu
            key={'menu_login_02'}
            isOnTop={isOnTop}
            // to={getInternalUrl(link5, tmpLang)}
            // isCurrentPage={link5 === currentPage}
            onClick={(e) => handleUserMenu({ key: 'login', domEvent: e })}
            //state={{ prevPath: location.pathname }}
          >
            <Title level={4}>
              {get(prismicData, 'node.data.login_menu_link', 'LOG IN')}
            </Title>
          </LinkMenu>
        </MenuMobileSignUpInner>
      )}
    </MenuMobileContainer>
  );
};

interface HeaderProps {
  data?: any;
  isOnTop: boolean;
  lang: string;
  theme: ITheme;
  isHomePage: boolean;
  currentPage: any;
  //location: any;
}

interface HeaderState {
  menuOpen: boolean;
  isMobile: boolean;
  moment: any;
  paused: boolean;
  reverse: boolean;
  isLoggedIn: boolean;
  isAdmin: boolean;
  isAgent: boolean;
  userName: string;
  userEmail: string;
  showBooking: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      isMobile: false,
      moment: null,
      paused: true,
      reverse: false,
      isLoggedIn: false,
      isAdmin: false,
      isAgent: false,
      showBooking: false,
      userName: '',
      userEmail: '',
    };
  }

  onRestart = () => {
    this.setState(
      {
        paused: false,
        reverse: false,
        moment: 0,
      },
      () => {
        this.setState({
          moment: null,
        });
      }
    );
  };

  onStart = () => {
    this.setState({
      paused: false,
      reverse: false,
      moment: null,
    });
  };

  onReverse = () => {
    this.setState({
      paused: false,
      reverse: true,
      moment: null,
    });
  };

  randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  handleUserMenu = ({ key, domEvent }): void => {
    if (exists(window)) {
      const loc = window.location.toString();
      const params = loc.split('?')[1];
      // console.log("LOC" + loc, window.location);
      let bookingRelPath = 'booking';

      if (localStorage.getItem('SHOWBOOKING') === 'dev') {
        bookingRelPath = 'dev-booking';
      }
      //  else if (localStorage.getItem("SHOWBOOKING") === "demo") {
      //   bookingRelPath = 'booking';
      // } else if (localStorage.getItem("SHOWBOOKING") === "prod") {
      //   bookingRelPath = 'booking';
      // } else if (localStorage.getItem("SHOWBOOKING") === "local") {
      //   bookingRelPath = 'booking';
      // }

      const lang = this.props.lang;

      if (key === 'logout') {
        this.onLogout(domEvent);
        setTimeout(function () {
          window.location.href =
            window.location.origin +
            `/${LANGUAGE[lang]}/${bookingRelPath}/?page=login`;
        }, 1000);
      } else {
        window.location.href =
          window.location.origin +
          `/${LANGUAGE[this.props.lang]}/${bookingRelPath}/?page=${key}`;
      }
    }
  };

  handleMessagePosted = (event) => {
    if (!this.isSameOrigin(event.origin)) {
      return;
    }
    const userInfo = event.data;
    if (event.data.sender === 'booking-child') {
      this.setState({
        userName: userInfo.userName,
        userEmail: userInfo.userEmail,
        isLoggedIn: userInfo.loggedIn,
        isAdmin: userInfo.isAdmin,
        isAgent: userInfo.isAgent
      });
    }
  };

  onLogout = (e) => {
    // const bookingIFrame = (document?.getElementById('iframe-booking') as HTMLIFrameElement)?.contentWindow;
    localStorage.removeItem('userTokenNikanti');
    localStorage.removeItem('userNameNikanti');
    localStorage.removeItem('userEmailNikanti');
    localStorage.removeItem('userRolesNikanti');
    // bookingIFrame?.postMessage({sender: "booking-parent", loggedIn: false, userName: '', userEmail: ''}, this.getOrigin());

    this.onMenuClose(e);
  };

  onMenuClose = (e) => {
    e.preventDefault();
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  getOrigin = () => {
    return window.location.hostname === 'localhost'
      ? 'http://localhost:3000/'
      : window.location.origin;
  };

  isSameOrigin = (origin: string) => {
    return (
      (origin === 'http://localhost:3000' &&
        window.location.hostname === 'localhost') ||
      origin === window.location.origin
    );
  };

  componentDidMount() {
    const { isHomePage } = this.props;

    if (!isHomePage) {
      this.onStart();
    }

    if (typeof window !== `undefined`) {
      // this.setState({ showBooking: localStorage.getItem("SHOWBOOKING") != null ? true : false });
      this.setState({ showBooking: true });
      const storedName = localStorage.getItem('userNameNikanti');
      const storedEmail = localStorage.getItem('userEmailNikanti');
      const storedRoles = localStorage.getItem('userRolesNikanti');

      const isAdmin = storedRoles ? storedRoles.indexOf('admin') >= 0 : false;
      const isAgent = storedRoles ? storedRoles.indexOf('agent') >= 0 : false;

      if (storedName != null && storedEmail != null) {
        // FIXME: why do we need to set state here
        this.setState({
          userName: storedName,
          userEmail: storedEmail,
          isLoggedIn: true,
          isAdmin,
          isAgent
        });
      }

      window.addEventListener('message', this.handleMessagePosted, false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    const { isOnTop, isHomePage } = this.props;
    const prevShowLogo = !prevProps.isOnTop || !prevProps.isHomePage;
    const showLogo = !isOnTop || !isHomePage;

    if (showLogo && !prevShowLogo) {
      this.onRestart();
    }
    if (!showLogo && prevShowLogo) {
      this.onReverse();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessagePosted, false);
  }

  renderBookingButton = (prismicData) => {
    return (
      <BookingButton
        type="primary"
        size="large"
        id="header_btn_bookNow"
        onClick={(e) => {
          this.handleUserMenu({ key: 'search', domEvent: e });
        }}
      >
        {get(prismicData, 'node.data.book_now_menu_button', 'Book Now')}
      </BookingButton>
    );
  };

  renderUserMenu = (prismicData) => {
    const { isLoggedIn, userName, isAdmin, isAgent } = this.state;

    // show manage coupons page if user is an admin
    const menu = isLoggedIn ? (
      <Menu onClick={this.handleUserMenu}>
        {!isAdmin || (
          <StyledGroupMenuItem key="admin-menus" title="ADMIN">
            <StyledMenuItem
              key="manage-coupons"
              data-testid="header_userMenu_account"
            >
              {get(
                prismicData,
                'node.data.manage_coupons_menu_link',
                'MANAGE COUPONS'
              )}
            </StyledMenuItem>
            <StyledMenuItem
              key="manage-tee-times"
              data-testid="header_userMenu_account"
            >
              {get(
                prismicData,
                'node.data.manage_tee_times_menu_link',
                'MANAGE TEE TIMES'
              )}
            </StyledMenuItem>
            <StyledMenuItem
              key="admin-manage-bookings"
              data-testid="header_userMenu_account"
            >
              {get(
                prismicData,
                'node.data.manage_user_bookings_menu_link',
                'MANAGE USER BOOKINGS'
              )}
            </StyledMenuItem>
            <StyledMenuItem
              key="admin-manage-customers"
              data-testid="header_userMenu_account"
            >
              {get(
                prismicData,
                'node.data.customer.list',
                'MANAGE USER ACCOUNT'
              )}
            </StyledMenuItem>
          </StyledGroupMenuItem>
        )}

        <StyledMenuItem
          key={isAgent ? "agency-details" : "user-profile"}
          data-testid="header_userMenu_account"
        >
          {get(prismicData, 'node.data.account_menu_link', 'ACCOUNT')}
        </StyledMenuItem>
        <StyledMenuItem
          key={isAgent ? "agency-manage-bookings" : "booking-history"}
          data-testid="header_userMenu_booking"
        >
          {get(
            prismicData,
            'node.data.manage_bookings_menu_link',
            'MANAGE BOOKINGS'
          )}
        </StyledMenuItem>
        <StyledMenuItem key="logout" data-testid="header_userMenu_logout">
          {get(prismicData, 'node.data.logout_menu_link', 'LOG OUT')}
        </StyledMenuItem>
      </Menu>
    ) : (
      <Menu onClick={this.handleUserMenu}>
        <StyledMenuItem key="login" data-testid="header_userMenu_login">
          {get(prismicData, 'node.data.login_menu_link', 'LOG IN')}
        </StyledMenuItem>
        <StyledMenuItem key="register" data-testid="signUp">
          {get(prismicData, 'node.data.sign_up_menu_link', 'SIGN UP')}
        </StyledMenuItem>
      </Menu>
    );

    const UserMenuButton = isLoggedIn ? (
      <MenuButtonContainer>
        <IconContainer>
          <StyledIcon type="menu" />
        </IconContainer>
        <div>
          <ProfileAvatar size={30} gap={0}>
            {userName.substring(0, 1).toUpperCase()}
          </ProfileAvatar>
        </div>
      </MenuButtonContainer>
    ) : (
      <MenuButtonContainer>
        <IconContainer>
          <StyledIcon type="menu" />
        </IconContainer>
        <div>
          <LogoutAvatar size={30} gap={0}>
            <Icon type="user" />
          </LogoutAvatar>
        </div>
      </MenuButtonContainer>
    );

    return <Dropdown overlay={menu}>{UserMenuButton}</Dropdown>;
  };

  render() {
    const { isOnTop, lang, theme, isHomePage, currentPage, prismicData } =
      this.props;

    const menu_items = get(prismicData, 'node.data.menu_items', []);
    const bookButton = get(prismicData, 'node.data.book_button', 'book');
    const telephoneNumber = get(prismicData, 'node.data.telephone_number', '');
    const phoneNumberNoSpaces =
      typeof telephoneNumber === 'string'
        ? telephoneNumber.replace(/\s+/g, '')
        : '';
    const logo = get(
      prismicData,
      'node.data.site_logo.document[0].data.logo',
      null
    );
    const smallLogo = get(
      prismicData,
      'node.data.site_logo.document[0].data.small_logo',
      null
    );

    const menuItem = menu_items.map((m, idx) => {
      const title = get(m, 'menu_item.document[0].data.title', '');
      const link = get(
        m,
        'menu_item.document[0].data.link.document[0].data.url',
        '/'
      );
      const lang = get(
        m,
        'menu_item.document[0].data.link.document[0].lang',
        'en'
      );

      return (
        <LinkMenu
          key={'menu_' + title + idx}
          isOnTop={isOnTop}
          to={getInternalUrl(link, lang)}
          isCurrentPage={link === currentPage}
          //state={{ prevPath: location.pathname }}
        >
          <Title level={4}>{title}</Title>
        </LinkMenu>
      );
    });

    const breakpointSize = theme.breakpoints.lg;
    const showLogo = !isOnTop || !isHomePage;

    // const urlParams = new URLSearchParams(window.location.search);

    return (
      <Container
        isOnTop={isOnTop}
        menuOpen={this.state.menuOpen}
        isHomePage={isHomePage}
      >
        <Media
          query={`(min-width: ${breakpointSize}px)`}
          defaultMatches={exists(window) && window.innerWidth > breakpointSize}
          render={() => (
            <>
              <TweenOneWithStyled
                paused={this.state.paused}
                reverse={this.state.reverse}
                moment={this.state.moment}
                animation={{
                  // height: 125 + 36,
                  height: 125,
                  duration: isHomePage ? ANIMATION_DURATION : 0,
                }}
                showLogo={showLogo}
                isOnTop={isOnTop}
                isHomePage={isHomePage}
              >
                <StyledToolbar isOnTop={isOnTop}>
                  <LeftContainer>
                    <TweenOne
                      style={{
                        opacity: 0,
                        position: 'absolute',
                        top: '20px',
                        // top: '56px',
                      }}
                      paused={this.state.paused}
                      reverse={this.state.reverse}
                      moment={this.state.moment}
                      animation={{
                        opacity: 1,
                        duration: isHomePage ? ANIMATION_DURATION : 0,
                        delay: isHomePage ? 300 : 0,
                      }}
                    >
                      {showLogo && (
                        <LinkMenu
                          to={getInternalUrl('/', lang)}
                          state={{ prevPath: location.pathname }}
                        >
                          {logo && (
                            <LogoImage alt={get(logo, 'alt')} data={logo} />
                          )}
                        </LinkMenu>
                      )}
                    </TweenOne>
                    <TweenOne
                      paused={this.state.paused}
                      reverse={this.state.reverse}
                      moment={this.state.moment}
                      animation={{
                        marginLeft: 125,
                        paddingTop: 40,
                        // x: 125,
                        // y: 20,
                        duration: isHomePage ? ANIMATION_DURATION : 0,
                      }}
                    >
                      <MenuContainer>{menuItem}</MenuContainer>
                    </TweenOne>
                  </LeftContainer>
                  <TweenOneRightContainer
                    paused={this.state.paused}
                    reverse={this.state.reverse}
                    moment={this.state.moment}
                    animation={{
                      // top:
                      //   exists(window) && window.innerWidth > 1200
                      //     ? 60 + 36
                      //     : 20 + 36,
                      top: exists(window) && window.innerWidth > 1200 ? 60 : 20,
                      duration: isHomePage ? ANIMATION_DURATION : 0,
                    }}
                  >
                    <RightContainer>
                      {!this.state.showBooking || (
                        <LanguageContainer>
                          {this.renderBookingButton(prismicData)}
                        </LanguageContainer>
                      )}
                      <LanguageContainer>
                        <SelectLanguage
                          value={LANGUAGE[lang]}
                          onChange={(value) => {
                            if (exists(window)) {
                              const loc: string = window.location.toString();
                              let params = loc.split('?')[1];

                              const currentChildPage =
                                localStorage.getItem('currentPage');

                              if (
                                currentChildPage &&
                                currentChildPage.length > 0 &&
                                params &&
                                params.length > 0
                              ) {
                                const pageParamStartPosition =
                                  params.indexOf('page=');
                                if (pageParamStartPosition >= 0) {
                                  const pageParamEndPosition = params.indexOf(
                                    '&',
                                    pageParamStartPosition
                                  ); //position of "&" after page=
                                  params =
                                    params.substring(
                                      0,
                                      pageParamStartPosition + 5
                                    ) + currentChildPage;
                                  if (pageParamEndPosition > 0) {
                                    params +=
                                      params.substring(pageParamEndPosition);
                                  }
                                } else {
                                  const startingChar =
                                    params.length <= 0 ? '' : '&';
                                  params +=
                                    startingChar + 'page=' + currentChildPage;
                                }
                              }

                              if (typeof params !== 'undefined') {
                                navigate(
                                  `/${value}/${currentPage}/?${params}`,
                                  {
                                    replace: true,
                                  }
                                );
                              } else {
                                navigate(`/${value}/${currentPage}`, {
                                  replace: true,
                                });
                              }
                            } else {
                              navigate(`/${value}/${currentPage}`, {
                                replace: true,
                              });
                            }

                            // typeof urlParams !== `undefined` ?
                            // navigate(`/${value}/${currentPage}?${urlParams}`, {
                            //   replace: true,
                            // }) : navigate(`/${value}/${currentPage}`, {
                            //   replace: true,
                            // });
                          }}
                          dropdownRender={(menu) => {
                            return <OptionStyled>{menu}</OptionStyled>;
                          }}
                        >
                          {keys(LANGUAGE).map(
                            (key, idx) =>
                              key !== lang && (
                                <Option
                                  key={'language_' + idx}
                                  value={LANGUAGE[key]}
                                >
                                  {LANGUAGE[key].toUpperCase()}
                                </Option>
                              )
                          )}
                        </SelectLanguage>
                      </LanguageContainer>
                      {!this.state.showBooking || (
                        <LanguageContainer>
                          {this.renderUserMenu(prismicData)}
                        </LanguageContainer>
                      )}

                      {telephoneNumber && !this.state.showBooking && (
                        <LanguageContainer>
                          <Icon type="phone" theme="filled" />
                          <p>{telephoneNumber}</p>
                        </LanguageContainer>
                      )}

                      {/* <BookButtonWithStyled color="inherit">
                        <BookLink to={'/app'}>{bookButton}</BookLink>
                      </BookButtonWithStyled> */}
                    </RightContainer>
                  </TweenOneRightContainer>
                </StyledToolbar>
              </TweenOneWithStyled>
            </>
          )}
        />
        <Media
          query={`(max-width: ${breakpointSize - 1}px)`}
          //defaultMatches={false}
          defaultMatches={
            exists(window) && window.innerWidth <= breakpointSize - 1
          }
          render={() => (
            <>
              <div>
                <MobileToolbar isOnTop={isOnTop} menuOpen={this.state.menuOpen}>
                  <MobileMenuButton onClick={this.onMenuClose} isMobile>
                    {this.state.isLoggedIn ? (
                      <CircleContainer>
                        <StyledMenuIcon type="user" />
                      </CircleContainer>
                    ) : (
                      <Icon type="menu" />
                    )}
                  </MobileMenuButton>
                  {/* {smallLogo && (
                        <SmallLogoAnimateWithStyled
                          transitionAppear={isHomePage}
                          transitionName="fade"
                          showProp="show"
                          isHomePage={isHomePage}
                          transitionEnter={isHomePage}
                        >
                          <SmallLogoContainer show={showLogo}>
                            <SmallLogoImage alt={get(smallLogo, 'alt')} src={get(smallLogo, 'url')} />
                          </SmallLogoContainer>
                        </SmallLogoAnimateWithStyled>
                      )} */}
                  <TweenOne
                    style={{ opacity: 0 }}
                    paused={this.state.paused}
                    reverse={this.state.reverse}
                    moment={this.state.moment}
                    animation={{
                      opacity: 1,
                      duration: isHomePage ? ANIMATION_DURATION : 0,
                    }}
                  >
                    <LinkMenu to={getInternalUrl('/', lang)}>
                      {smallLogo && (
                        <LogoImage
                          small
                          alt={get(smallLogo, 'alt')}
                          data={smallLogo}
                        />
                      )}
                    </LinkMenu>
                  </TweenOne>
                  {/* <ButtonWithStyled isMobile>
                        <BookLink to={'/app'}>{bookButton}</BookLink>
                      </ButtonWithStyled> */}
                  {phoneNumberNoSpaces && (
                    <ButtonWithStyled isMobile>
                      <a href={`tel:${phoneNumberNoSpaces}`}>
                        <Icon type="phone" theme="filled" />
                      </a>
                    </ButtonWithStyled>
                  )}
                </MobileToolbar>
                <div
                  // className={'MenuMobileContainer'}
                  aria-hidden={this.state.menuOpen}
                >
                  <MenuMobile
                    //className={'MenuMobileContainer'}
                    logo={logo}
                    lang={lang}
                    userName={this.state.userName}
                    userEmail={this.state.userEmail}
                    isLoggedIn={this.state.isLoggedIn}
                    isAdmin={this.state.isAdmin}
                    isAgent={this.state.isAgent}
                    showBooking={this.state.showBooking}
                    isOnTop={isOnTop}
                    open={this.state.menuOpen}
                    currentPage={currentPage}
                    handleUserMenu={this.handleUserMenu}
                    close={this.onMenuClose}
                    onLogout={this.onLogout}
                    onMenuClose={this.onMenuClose}
                    prismicData={prismicData}
                  >
                    {menuItem}
                  </MenuMobile>
                </div>
              </div>
            </>
          )}
        />
      </Container>
    );
  }
}

export default withTheme(Header);
const queryHeaderMenu = graphql`
  query MenuQuery {
    allPrismicHeaderMenu {
      ...Header
    }
  }
`;
