import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { getInternalUrl } from '../../utils';
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

const Link = props => {
  const {
    children,
    to,
    activeClassName,
    partiallyActive,
    target = '',
    ...other
  } = props;

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  const file = /\.[0-9a-z]+$/i.test(to);
  const otherProps =
    target === '_blank' ? { ...other, rel: 'noopener', target } : { ...other };

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    if (file) {
      return (
        <a href={to} {...otherProps}>
          {children}
        </a>
      );
    }

    return (
      <GatsbyLink
        to={to}
        // to={getInternalUrl(to, '')}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...otherProps}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} {...otherProps}>
      {children}
    </a>
  );
};

export default Link;
