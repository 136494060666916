import axios from 'axios';

export const sendMail = async (
  to: string,
  subject: string,
  html: string,
  text?: string,
  file?: File | File[] | null,
  cc?: string | null,
  bcc?: string | null,
) => {
  try {
    if (!process.env.GATSBY_SES_ENDPOINT) {
      throw new Error('SES endpoint is not in enviroment variables');
    }

    const formData = new FormData();

    formData.append('secret', process.env.GATSBY_SES_SECRET || '');
    formData.append('to', to);
    formData.append('subject', subject);
    formData.append('html', html);
    formData.append('text', text || '');

    if (typeof cc !== 'undefined' && cc) {
      formData.append('cc', cc);
    }

    if (typeof bcc !== 'undefined' && bcc) {
      formData.append('bcc', bcc);
    }

    if (typeof file !== 'undefined' && file) {
      const fileList = Array.isArray(file) ? file : [file];
      for (let i = 0; i < fileList.length; i++) {
        formData.append(`files[${i}]`, fileList[i]);
      }
    }

    const response = await axios.post(process.env.GATSBY_SES_ENDPOINT || '', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (e) {
    throw e;
  }
};
